import jwt_decode from 'jwt-decode';

export function getToken(index) {
	return window.localStorage.getItem(index);
}

export function setToken(index,value) {
	window.localStorage.setItem(index,value);
}

export function deleteToken(index){
	window.localStorage.removeItem(index);	
}

export function getTokenID() {
  const token =  window.localStorage.getItem('spm_token');
  const id = jwt_decode(token).uID;
  return id;
}
