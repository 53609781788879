/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Container, Table, Card, Badge, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import Header from './../../components/Header';
import NotificationAlert from "react-notification-alert";
import { getAllLinkData } from './../../api/request';
import dayjs from 'dayjs';

const liStyle = {
  display: 'flex',
  justifyContent: 'space-between'
}

export default function LinkGenerated() {
  const notificationAlertRef = React.useRef(null);
  const window_screen = window.screen.width;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect( () => {
    getAllLink();
  }, []);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            <b>
              {title}
            </b>
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getAllLink = async () => {
    const res = await getAllLinkData();
    
    if(res.status === 200) {
      setData(res.data);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  const verifyClient = (c) => {
    return (c === null) ? "Información no registrada" : c;
  }

  const verifyActive = (end_date) => {
    var d1 = new Date();
    var d2 = new Date(end_date);

    return (d1 < d2) ? <Badge color="success">Si</Badge> : <Badge color="danger">No</Badge>;
  }

  const buildLink = (token) => {
    return `https://cliente.eshopspm.net?token=${token}`;
  }
  
  const copyCatalogLink = (token) => {
    navigator.clipboard.writeText(`https://cliente.eshopspm.net?token=${token}`);
    notifyAlert("success", "Mensaje", "Link creado y ya ha sido copiado");
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

  		<Header back bottom />
			<Container>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px'
        }}>
          <h2>Link catálogo</h2>
          <Link
            className="btn btn-sm"
            style={{
              height: '32px',
              color: '#ffffff',
              backgroundColor: '#004899',
              border: '1px solid #004899'
            }}
            to="/link/create"
          > Crear </Link>
        </div>

        {
          (loading) ?
          <>
            <div className="text-center">
              <Spinner style={{color: '#004899'}} />
            </div>
          </> 
          :
          <>
            {
              (window_screen < 760) ? <>
                <ul style={{
                  listStyle: 'none',
                  padding: '0px'
                }}>
                  {
                    data.map( (d, i) => (
                      <li key={i} style={{
                        marginBottom: '10px'
                      }}>
                        <Card style={{
                          padding: '10px'
                        }}>
                          <ul style={{
                            display: 'grid',
                            listStyle: 'none',
                            padding: '0px'
                          }}>
                            <li style={liStyle}>
                              <b>Cliente: </b>
                              <span>
                                {verifyClient(d.client)}
                              </span>
                            </li>
                            <li style={liStyle}>
                              <b>Activo: </b>
                              <span>
                                {verifyActive(d.end_date)}
                              </span>
                            </li>
                            <li style={liStyle}>
                              <b>Fecha de creación : </b>
                              <span>
                                {dayjs(d.start_date).format('DD-MM-YYYY HH:mm')}
                              </span>
                            </li>
                            <li style={liStyle}>
                              <b>Fecha de expiración: </b>
                              <span>
                                {dayjs(d.end_date).format('DD-MM-YYYY HH:mm')}
                              </span>
                            </li>
                            <li>
                              <span style={{
                                display: 'block',
                                marginTop: '12px',
                                marginBottom: '12px',
                                textAlign: 'center'
                              }}>
                                {buildLink(d.token)}
                              </span>
                            </li>
                            <div>
                              <Button
                                size="sm"
                                style={{
                                  width: '100%',
                                  backgroundColor: '#004899',
                                  border: '1px solid #004899'
                                }}
                                onClick={() => copyCatalogLink(d.token)}
                              > Copiar </Button>
                            </div>
                          </ul>
                        </Card>
                      </li>
                    ))
                  }
                </ul>
              </> : 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Cliente </th>
                      <th> Activo </th>
                      <th> Fecha de creación </th>
                      <th> Fecha de expiración </th>
                      <th> Link </th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map( (d, i) => (
                        <tr key={i}>
                          <th>{++i}</th>
                          <td>{verifyClient(d.client)}</td>
                          <td>{verifyActive(d.end_date)}</td>
                          <td>{dayjs(d.start_date).format('DD-MM-YYYY')}</td>
                          <td>{dayjs(d.end_date).format('DD-MM-YYYY')}</td>
                          <td>{buildLink(d.token)}</td>
                          <td>
                            <Button
                              size="sm"
                              style={{
                                backgroundColor: '#004899',
                                border: '1px solid #004899'
                              }}
                              onClick={() => copyCatalogLink(d.token)}
                            > Copiar </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </>
            }
          </>
        }
      </Container>
    </>
  )
}
