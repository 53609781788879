/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { Spinner, Button, Container, Row, Col } from "reactstrap";
import Header from "./../../components/Header";
import { getAllBrandData, getAllTransitBrandData, downloadCatalogData } from './../../api/request'
import NotificationAlert from "react-notification-alert";

export default function Catalog() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataTransit, setDataTransit] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect( () => {
    execute();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const execute = async () => {
    await getBrand();
    await getTransitBrand();
  }

  const getBrand = async () => {
    setLoading(true);
    const res = await getAllBrandData();
    console.log(res);

    if(res.status === 200) {
      setError(false);
      setData(res.data.brands);
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false);
  }

  const getTransitBrand = async () => {
    setLoading(true);
    const res = await getAllTransitBrandData();
    console.log(res);

    if(res.status === 200) {
      setError(false);
      setDataTransit(res.data.brands);
    }
    else {
      setError(true);
      setDataTransit([]);
    }
    setLoading(false);
  }

  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }
 
  const downloadCatalog = async (brand, price) => {
    setDisableBtn(true);
    const jsn = {
      "brand": brand,
      "warehouse": "01",
      "price": price
    }

    const res = await downloadCatalogData(jsn);

    if(res.success === true) {
      const link = document.createElement('a');
      link.download = `Catalogo - ${String(brand).toUpperCase()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al descargar el catalogo");
    }
    setDisableBtn(false);
  }

  const downloadTransitCatalog = async (brand, price) => {
    setDisableBtn(true);

    const jsn = {
      "brand": brand,
      "warehouse": "MT",
      "price": price
    }

    const res = await downloadCatalogData(jsn);
  
    if(res.success === true) {
      const link = document.createElement('a');
      link.download = `Catalogo - ${String(brand).toUpperCase()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al descargar el catalogo");
    }

    setDisableBtn(false);
  }
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header bottom />
      <Container>
        <div>
          <h2>Catalogo</h2>
        </div>

        {
          (loading) ? <>
            <div style={{
              marginTop: '15px',
              textAlign: 'center'
            }}>
              <Spinner style={{
                color: '#004899'
              }} />
            </div>
          </>: <>
            {
              (error) ? <> 
              <div style={{
                marginTop: '15px',
                textAlign: 'center'
              }}>
                <p>Se ha presentado un error. Por favor intente de nuevo mas tarde. </p>
                <Button style={{
                  backgroundColor: '#004899',
                  border: 0
                }}
                  onClick={() => getBrand()}> Cargar de nuevo </Button>
              </div>
              </> : <>
                <Row style={{
                  marginTop: '15px',
                  marginBottom: '15px'
                }}>
                  {
                    data.map((b, i) => (
                      <Col md="3" key={i}>
                        <div className="contPiece">
                          <img className="piecePicture" alt="..." src={b.logo}/>
                          <span className="pieceTitle">{capitalize(b.name)}</span>
                          <Button
                            disabled={disableBtn}
                            style={{
                              border: 0,
                              marginTop: '10px',
                              backgroundColor: (disableBtn) ? "#6c757d" : '#004899',
                            }}
                            onClick={() => downloadCatalog(b.code, true)}> Descargar </Button>
                          <br />
                          <Button
                            disabled={disableBtn}
                            style={{
                              border: 0,
                              marginTop: '10px',
                              backgroundColor: (disableBtn) ? "#6c757d" : '#004899',
                            }}
                            onClick={() => downloadCatalog(b.code, false)}> Descargar sin precio </Button>
                        </div>
                      </Col>
                    ))
                  }
                </Row>

                <hr />
                <div>
                  <h2> Transito </h2>
                </div>
                <Row style={{
                  marginTop: '15px',
                  marginBottom: '15px'
                }}>
                  {
                    dataTransit.map((b, i) => (
                      <Col md="3" key={i}>
                        <div className="contPiece">
                          <img className="piecePicture" alt="..." src={b.logo}/>
                          <span className="pieceTitle">{capitalize(b.name)}</span>
                          <Button
                            disabled={disableBtn}
                            style={{
                              border: 0,
                              marginTop: '10px',
                              backgroundColor: (disableBtn) ? "#6c757d" : '#004899',
                            }}
                            onClick={() => downloadTransitCatalog(b.code, true)}> Descargar </Button>
                            <br />
                            <Button
                            disabled={disableBtn}
                            style={{
                              border: 0,
                              marginTop: '10px',
                              backgroundColor: (disableBtn) ? "#6c757d" : '#004899',
                            }}
                            onClick={() => downloadTransitCatalog(b.code, false)}> Descargar sin precio </Button>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </>
            }
          </>
        }
      </Container>
    </>
  )
}


