/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Container, Button } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Header from '../components/Header';
import Footer from "../components/Footer";
import { getBlogByIdData } from "./../../../api/page";
import dayjs from "dayjs";

export default function ViewBlog() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    title: "",
    content: "",
    image: "",
    date: "",
    publisher: ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const getPost = async () => {
      const res = await getBlogByIdData(id);

      if(res.status === 200) {
        const { data } = res;
        setData({
          title: data.title,
          content: data.description,
          image: data.picture,
          date: dayjs(data.date).format("DD/MM/YYYY"),
          publisher: data.publisher
        });
        setError(false);
        setLoading(false);
      }
      else {
        setError(true);
        setLoading(false);
      }
    }

    getPost();
  }, []);
  
  return (
    <>
      {
        (loading) ?
          <div style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`
          }}>
            <Spinner style={{ color: "#004899" }} />
          </div>
        :
        <>
          {
            (error) ?
              <div style={{
                width: `100%`,
                height: `100vh`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                flexDirection: `column`
              }}>
                <h1 style={{
                  color: `#004899`,
                  fontWeight: `700`
                }}>
                  Este post no existe
                </h1>
                  <br />
                  <br />
                  <Link
                    style={{
                      textDecoration: `none`,
                      color: `#004899`,
                      marginLeft: `10px`
                    }}
                    to="/blog">
                    <Button
                      style={{
                        backgroundColor: `#004899`,
                        border: `none`,
                        fontWeight: `700`
                      }}
                      size="lg">
                      Volver
                    </Button>
                  </Link>
              </div>
            : <>
                <Header active="blog" />

                <div style={{
                  width: `100%`,
                  textAlign: `center`,
                  marginTop: `50px`,
                  marginBottom: `50px`,
                }}>
                  <Container>
                    <img
                      alt={data.title}
                      style={{
                        width: `100%`,
                        height: `350px`,
                        objectFit: `scale-down`,
                      }}
                      src={data.image}
                    />
                    <div style={{
                      width: `100%`,
                    }}>
                      <ul style={{
                        display: `flex`,
                        justifyContent: `center`,
                        listStyle: `none`,
                        padding: `0px`,	
                      }}>
                        <li>
                          <i 
                            style={{
                              color: `#004899`,
                              marginRight: "5px"
                            }}
                            className="fas fa-user" />
                          <span> {data.publisher} </span>
                        </li>
                        <li style={{marginLeft: "25px"}}>
                          <i 
                            style={{
                              color: `#004899`,
                              marginRight: "5px"
                            }}
                            className="far fa-calendar-alt" />
                          <span> {data.date} </span>
                        </li>
                      </ul>
                    </div>
                    <h1 style={{
                      marginTop: `20px`,
                      color: `#004899`,
                      fontWeight: `700`
                    }}>
                      {data.title}
                    </h1>
                    <div>
                      <p style={{
                        marginTop: `20px`,
                        textAlign: `justify`,
                        lineHeight: `2em`,
                      }}>
                        {data.content}
                      </p>
                    </div>
                    <div style={{
                      textAlign: `center`,
                    }}>
                      <Link
                        style={{
                          textDecoration: `none`,
                          color: "#333" 
                        }}
                        to="/blog">
                        <Button
                          style={{
                            backgroundColor: `#004899`,
                            border: `none`,
                            borderRadius: `0px`,
                            width: `200px`,
                            marginTop: `20px`,
                          }}
                          color="primary"
                          size="lg"
                        >
                          <i className="fas fa-arrow-left" />
                          <span style={{
                            marginLeft: `10px`
                          }}>
                            Regresar
                          </span>
                        </Button>
                      </Link>

                    </div>
                  </Container>
                </div>

                <Footer />
              </> 
          }
        </>
      }

    </>
  )
}

