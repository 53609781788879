import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap'
import { AuthContext } from "./context/AuthContext";
import { getToken, setToken, deleteToken } from './utils/token';
import { checkUpdateData } from './api/request';

import Auth from "./views/Auth/Login";
import Reset from './views/Auth/Reset';
import Home from "./views/Home/Home";
import Brand from "./views/Brand/Brand";
import CartIndex from "./views/Cart/CartIndex";
import Pieces from "./views/Pieces/Pieces";
import History from "./views/History/History";
import HistoryDetails from "./views/History/HistoryDetails";
import LinkGenerated from "./views/Link/LinkGenerated";
import LinkCreate from "./views/Link/LinkCreate";
import Stats from "./views/Stats/Stats";
import Config from "./views/Config/Config";
import Transit from './views/Transit/Transit';
import BrandTransit from './views/Transit/BrandTransit';
import PiecesTransit from './views/Transit/PiecesTransit';
import UpdateBar from './components/UpdateBar';

import Page from './views/Page/Page';
import FAQ from './views/Page/Faq/FAQ';
import Blog from './views/Page/Blog/Blog';
import Contact from './views/Page/Contact/Contact';
import ViewBlog from './views/Page/Blog/ViewBlog';
import Catalog from './views/Catalog/Catalog';

export default function App() {
  const initialLoginState = {
    isLoading: true,
    isSignout: false,
    spmToken: null,
    updateApp: false
  }

  const loginReducer = (prevState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'RESTORE_TOKEN':
        return {
          ...prevState,
          spmToken: action.token,
          updateApp: action.update,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          spmToken: action.token,
          updateApp: action.update,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          spmToken: null,
          isLoading: false,
        };
      case 'WEBAPP_UPDATE':
        return {
          ...prevState,
          updateApp: action.update 
        };
    }
  }
  
  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);

  const authContext = React.useMemo(
    () => ({
      signIn: async data => {
        try {
          console.log(data);
          setToken('spm_token', data.token);
        } 
        catch (e) {
          console.log(e)
        }

        dispatch({ type: 'LOGIN', token: data.token, update: data.update });
      },
      signOut: () => {
        deleteToken('spm_token');
        dispatch({ type: 'LOGOUT', token: null });
      },
      webapp_update: (data) => {
        dispatch({ type: 'WEBAPP_UPDATE', update: data });
      }
    }),
    []
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      var activeUpdate;

      try {
        userToken = getToken('spm_token');
        
        if(userToken !== null) {
          const res = await checkUpdateData();
          activeUpdate = res.data.update;
        }
      } 
      catch (e) {
        console.log(e);
      }

      
      dispatch({ type: 'RESTORE_TOKEN', token: userToken, update: activeUpdate });
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      {
        loginState.isLoading ? 
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner style={{color: '#004899'}} />
          </div> : 
        <AuthContext.Provider value={authContext}>
          <BrowserRouter>
            {
              loginState.spmToken === null ? 
                ( 
                  <Routes>
                    <Route path="/" element={<Page />} /> 
                    <Route path="/faq" element={<FAQ />} /> 
                    <Route path="/blog" element={<Blog />} /> 
                    <Route path="/blog/:id" element={<ViewBlog />} /> 
                    <Route path="/contact" element={<Contact />} /> 
                    <Route path="/login" element={<Auth />} /> 
                    <Route path="/reset" element={<Reset />} /> 
                    <Route path="*" element={<Page />} />
                  </Routes>
                  ) :
                  ( 
                  <>
                  {
                    (loginState.updateApp) ? <> 
                      <UpdateBar />
                    </> : <></>
                  }
                    <Routes>
                      <Route path="/home" element={<Home /> } /> 
                      <Route path="/brand/:bn" element={<Brand /> } /> 
                      <Route path="/cart" element={<CartIndex /> } /> 
                      <Route path="/catalog" element={<Catalog /> } /> 
                      <Route path="/piece/:pi" element={<Pieces />} /> 
                      <Route path="/historical" element={<History /> } />
                      <Route path="/historical/:id" element={<HistoryDetails /> } />
                      <Route path="/link" element={<LinkGenerated /> } /> 
                      <Route path="/link/create" element={<LinkCreate /> } /> 
                      <Route path="/stats" element={<Stats /> } /> 
                      <Route path="/config" element={<Config /> } /> 
                      <Route path="/transit" element={<Transit /> } /> 
                      <Route path="/transit/brand/:bn" element={<BrandTransit /> } /> 
                      <Route path="/transit/piece/:pi" element={<PiecesTransit /> } /> 
                      <Route path="*" element={<Home />} />
                    </Routes>
                  </>
                ) 
            }
          </BrowserRouter>
        </AuthContext.Provider>
      }
    </>
  )
}
