import React, {useContext} from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { checkUpdateData } from './../api/request'
import { useIdleTimer } from 'react-idle-timer';
import { AuthContext } from './../context/AuthContext';

export default function Header({back, bottom}) {
  const navigate = useNavigate();
  const [collapse, setCollapse] = React.useState(false);
  const { webapp_update } = useContext(AuthContext);
  
  const handleOnIdle = async () => {
    const res = await checkUpdateData();
    if(res.success === 200) {
      webapp_update(res.data.update);
    }
  }
  
  useIdleTimer({
    timeout: 3600000,
    //timeout: 6000,
    onIdle: handleOnIdle,
    debounce: 500
  });
  
  return (
    <>
      <div style={{
        backgroundColor: '#ffffff',
        marginBottom: (!bottom) ? '0px' : `10px`
      }}>
        <Navbar color="faded" light>
          {
            (back) ? <> 
              <NavbarBrand className="me-auto" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left"/>
              </NavbarBrand>
            </>: <> 
              <NavbarBrand className="me-auto" href="/">
                <img alt="..." width={42} src={require('./../../src/assets/img/logo.png').default} />
              </NavbarBrand>
            </>
          }

          <div>
            <Link style={{
              display: "block"
            }}
            to="/transit"> 
              <img 
                alt="transit" 
                style={{
                  width: '1.5em',
                  marginRight: '10px'
                }}
                src={require('./../assets/img/transit.png').default}/>
            </Link>
          </div>
          <div>
            <Link style={{
              display: "block"
            }}
            to="/cart"> 
              <img 
                alt="cart" 
                style={{
                  width: '1.5em',
                  marginRight: '10px'
                }}
                src={require('./../assets/img/cart.svg').default}/>
            </Link>
          </div>

          <NavbarToggler
            className="me-2"
            style={{
              border: '0',
              fontSize: '15px'
            }}
            onClick={() => setCollapse(!collapse)}
          />

          <Collapse navbar isOpen={collapse}>
            <Nav navbar>
              <NavItem>
                <NavLink href="/home">
                  Inicio
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/cart">
                  Carrito
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/catalog">
                  Catalogo
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/historical">
                  Historial
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/stats">
                  Estadística
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/link">
                  Generacion Link
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/config">
                  Configuraciones
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  )
}
