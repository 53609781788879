import React, { useEffect, useState } from "react";
import { Table, Button, Spinner, Container, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from './../../components/Header';
import { getAllBrandData, getClientsData, setClientAccessData } from './../../api/request';
import NotificationAlert from "react-notification-alert";
import { getTokenID } from './../../utils/token';

export default function LinkCreate() {
  const window_screen = window.screen.width;
	const notificationAlertRef = React.useRef(null);
	const [loading, setLoading] = useState(false);
  const [userID, setUserId] = useState(0);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [arrClient, setArrClient] = useState([]);
	const [client, setClient] = useState({
		name: '', 
		contact: '', 
		email: '',
		cardId: '',
		phone: '',
		state: '',
		city: '',
		address:''
	});
	const [loadingClient, setloadingClient] = useState(false);
	const [showModal, setShowModal] = useState(false);
  const [brandCheckArr, setBrandCheckArr] = useState([]);
	const [loadingBtn, setLoadingBtn] = useState(false);
  const [checkPrice, setCheckPrice] = useState(false);
  const [allSelect, setAllSelect] = useState(false);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            <b>
              {title}
            </b>
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  function delay(n){
    return new Promise(function(resolve){
      setTimeout(resolve,n*1000);
    });
  }

  useEffect(() => {
    getAllBrand()
    setUserId(getTokenID());
  }, []);

  const getAllBrand = async () => {
    setLoading(true);

    const res = await getAllBrandData();
    console.log(res);
    if(res.status === 200) {
      setData(res.data.brands);
      setError(false);
    }
    else {
      setData(res.data.brands);
      setError(true);
    }
    setLoading(false);
  }

  const getClients = async ()  => {
    setloadingClient(true);

    if(String(search).trim() === "") {
      notifyAlert("danger", "Mensaje", "Debe ingresar el nombre del cliente", 5);
    }
    else {
      const res = await getClientsData(search);

      if(res.status === 200) {
        setArrClient(res.data.data);
      }
      else {
        setShowModal(!showModal);
        notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud", 5);
      }
    }

    setloadingClient(false);
  }

  const setClientData = (c) => {
    setClient(c);
    setShowModal(false);
  }

  const setCheckInput = (inp, check, code) => {
    const arr = [...brandCheckArr];

    if(arr.length === 0) {
      arr.push({"name": code, "input": inp, "check": check});
    }
    else {
      var exist = false;
      
      if(check === false) {
        for(const i in arr) {
          if(arr[i].name === code) {
            arr.splice(i, 1);
            break;
          }
        }
      }
      else {
        for(const data of arr) {
          if(data.name === code) {
            notifyAlert("warning", "Mensaje", "Esta marca ya fue seleccionada.")
            exist = true;
            break;
          }
        }

        if(exist === false) {
          arr.push({"name": code, "input": inp, "check": check});
        }
      }
    }

    setBrandCheckArr(arr);
  }

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  const generatedLink = async () => {
    setLoadingBtn(true);
    
    if (brandCheckArr.length === 0) {
      notifyAlert("warning", "Mensaje", "Debe seleccionar la marca para el catálogo");
    }
    else if(client.name === "") {
      notifyAlert("warning", "Mensaje", "Debe seleccionar a su cliente", 5);
    }
    else {
      const token = Math.random().toString(36).slice(2);
      const arrBrands = [...brandCheckArr]
      var brands = '';

      for(var i in arrBrands) {
        brands += (i === arrBrands.length-1) ? `${brandCheckArr[i].name}` : `${brandCheckArr[i].name},`;
      }

      const jsn = {
        "client_id": (client.new) ? 'NULL' : client.code,
        "token": token,
        "brands": brands.substring(0,brands.length-1),
        "price": (checkPrice) ? 1 : 0,
      }
      console.log(jsn);

      const res = await setClientAccessData(jsn);
      console.log(res);

      if(res.data === "True") {
        unsecuredCopyToClipboard(`https://cliente.eshopspm.net?token=${token}`)
        notifyAlert("success", "Mensaje", "Link creado y ya ha sido copiado");
        await delay(3);
        window.location.href = '/link';
      }
      else {
        notifyAlert("danger", "Mensaje", "Ha ocurrido un error al crear el link para el cliente");
      }
    }

    console.log(checkPrice);
    setLoadingBtn(false);
  }

  const setAllInput = (check) => {
    console.log(check);
    console.log(data);
    var arrFinal= [];

    if(check) {
      console.log("TODOS");

      for(const i in data) {
        console.log(`ch${i}`);
        document.getElementById(`ch${i}`).checked = true;
        arrFinal.push({"name": data[i].code, "input": `ch${i}`, "check": true});
      }
    }
    else {
      console.log("NADA");

      for(const i in data) {
        document.getElementById(`ch${i}`).checked = false;
      }
      arrFinal = [];
    }
    
    setBrandCheckArr(arrFinal);
    setAllSelect(!allSelect);
  }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

  		<Header back bottom />
			<Container>
        <h2>Creacion link catálogo</h2>

        <hr />
        <ul style={{
          listStyle: 'none',
          padding: '0px',
          display: "flex",
          justifyContent: "space-between"
        }} >
          <li>
            <h4>Marcas</h4>
          </li>
          <li>
            <label>Seleccionar todos </label>
            <input 
              style={{
                position: 'relative',
                top: '3px',
                width: '15px',
                height: '15px',
                marginLeft: '10px'
              
              }}
              value={allSelect}
              type="checkbox" 
              onChange={(val) => setAllInput(val.target.checked)}/>
          </li>
        </ul>
        <div style={{
          maxHeight: '280px',
          overflowY: 'scroll'
        }}>
          {
            (loading) ? <>
              <div style={{textAlign: 'center'}}>
                <Spinner style={{
                  color: '#004899'
                }} />
              </div>
            </> :
            (error) ? <>
              <div style={{textAlign: 'center'}}>
                <label> Ha ocurrido un error al obtener las marcas. </label>
                <label> Intente nuevamente. </label>
                <Button 
                  style={{
                    width: '100%',
                    marginTop: '10px',
                    backgroundColor: '#004899',
                    border: '1px solid #004899'
                  }}
                  onClick={() => getAllBrand()}>Obtner marcas</Button>
              </div>
            </>: 
            <>
              {
                data.map((d, i) => (
                  <Card key={i} style={{padding: '5px', marginBottom: '10px', height: '110px'}}>
                    <ul className="cardCart" style={{marginTop: '0px', alignItems: 'center' }}>
                      <li>
                        <img style={{
                          width: '100px'
                        }} alt="..." src={d.logo} />
                      </li>
                      <li style={{width: '100%', textAlign: 'center'}} >
                        <ul className="cartDetails">
                          <li>
                            <span className="titlePiece" style={{
                              fontSize: '20px'
                            }}>{d.name}</span>
                          </li>
                          <li>
                            <label>Seleccionar</label>
                            <input 
                              style={{
                                position: 'relative',
                                top: '2px',
                                width: '15px',
                                height: '15px',
                                marginLeft: '10px'
                            }}
                              id={`ch${i}`} 
                              type="checkbox" 
                              onChange={(val) => setCheckInput(`ch${i}`, val.target.checked, d.code )}/>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Card>
                ))
              }
            </>
          }
        </div>

        <hr />
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px'
          }}>
            <h4>Cliente</h4>
            <Button
              className="btn btn-sm"
              style={{
                height: '32px',
                color: '#ffffff',
                backgroundColor: '#004899',
                border: '1px solid #004899'
              }}
              onClick={() => setShowModal(true)}
            > Buscar </Button>
          </div>
        </div>

        <div>
          <ul id="clientData">
            <li>
              <b>Nombre:</b>
              <span>{client.name}</span>
            </li>
            <li>
              <b>RIF:</b>
              <span>{client.rif}</span>
            </li>
            <li>
              <b>Telefono:</b>
              <span>{client.phone}</span>
            </li>
            <li>
              <b>E-mail:</b>
              <span>{client.email}</span>
            </li>
            <li>
              <b>Dirección:</b>
              <br />
              <span style={{textAlign:'right'}}>{client.address}</span>
            </li>
            {
              (userID === 29) ? <>
                <li>
                  <b>Precio de Showroom:</b>
                  <br />
                  <input 
                    style={{
                      width: '30px',
                      height: '30px',
                    }}
                    type="checkbox"
                    checked={checkPrice}
                    onChange={() => setCheckPrice(!checkPrice)}
                  />
                </li>
              </> : <></>
            }
          </ul>
        </div>

        <div style={{marginBottom: '25px'}}>
          {
            (loadingBtn) ? <>
              <div style={{textAlign: 'center', marginTop:'0px'}}>
                <Spinner style={{color: '#004899'}} />
              </div>
            </> :
            <>
              <Button 
                style={{
                  width: '100%',
                  backgroundColor: '#004899',
                  border: '1px solid #004899'
                }}
                onClick={() => generatedLink()}>Generar</Button>
            </>
          }
        </div>

        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(!showModal)}>Cliente</ModalHeader>
          <ModalBody>
            <div style={{display:'flex'}}>
              <input 
                style={{
                  marginRight: 3
                }}
                value={search}
                className="form-control" 
                onChange={val => setSearch(val.target.value)}/>
              <Button
                onClick={() => getClients()}>Buscar</Button>
            </div>
            <div>
              {
                (loadingClient) ? <>
                <div style={{textAlign: 'center', marginTop:'10px'}}>
                  <Spinner style={{color: '#004899'}} />
                </div>
                </> : <>
                    {
                      (window_screen < 560) ? <>
                        <ul style={{
                          listStyle: 'none',
                          padding: '0px',
                          marginTop: '10px',
                          maxHeight: '350px',
                          overflow: 'scroll'
                        }}>
                          {
                            arrClient.map( (c, i) => (
                              <li 
                                key={i} 
                                style={{
                                  marginBottom: '10px'
                                }}
                              >
                                <Card style={{
                                  padding: '10px'
                                }}>
                                  <div>
                                    <div style={{
                                      display: 'grid'
                                    }}>
                                      <span>{(c.name === '') ? 'Data no registrada' : c.name}</span>
                                      <span>{(c.rif === '') ? 'Data no registrada' : c.rif}</span>
                                      <span>{(c.state === '') ? 'Data no registrada' : c.state}</span>
                                      <span>{(c.city === '') ? 'Data no registrada' : c.city}</span>
                                      <Button 
                                        size="sm"
                                        style={{
                                          backgroundColor: '#004899',
                                          border: '1px solid #004899'
                                        }}
                                        onClick={() => setClientData(c)}>Seleccionar</Button>
                                    </div>
                                  </div>
                                </Card>
                              </li>
                            ))
                          }
                        </ul>
                      </> : 
                      <>
                        {
                          <div style={{
                            maxHeight: '350px',
                            overflow: 'scroll'
                          }}>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>RIF</th>
                                  <th>Ciudad</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  arrClient.map( (c, i) => (
                                    <tr key={i}>
                                      <td>
                                        {(c.name === '') ? 'No data' : c.name}
                                      </td>
                                      <td>
                                        {(c.rif === '') ? 'No data' : c.rif}
                                      </td>
                                      <td>
                                        {(c.city === '') ? 'No data' : c.city}
                                      </td>
                                      <td>
                                        <Button 
                                          size="sm"
                                          variant="secondary"
                                          onClick={() => setClientData(c)}>Seleccionar</Button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          </div>
                        }
                      </>
                    }
                  </>
                }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setShowModal(!showModal)}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

      </Container>
    </>
  )
}
