/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Container } from "reactstrap";
import Header from './../components/Header';
import Footer from "./../components/Footer";
import { getFaqData } from "./../../../api/page";

export default function FAQ() {
  const [loading, setLoading] = useState(true);
  const [FAQData, setFAQData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getFAQ = async () => {
      const res = await getFaqData();
      if(res.status === 200) {
        const { data } = res;
        setFAQData(data);
        setLoading(false);
      }
    }

    getFAQ();
  }, []);
  
  return (
    <>
        {
        (loading) ?
          <div style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`
          }}>
            <Spinner style={{ color: "#004899" }} />
          </div>
        :
        <>
          <Header active="faq" />
    
          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`,
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`
              }}>
                FAQ
              </h1>
    
              <ul style={{
                marginTop: `50px`,
                padding: `0px`,
                listStyle: `none`,
                textAlign: `left`,
              }}>
                {
                  FAQData.map((item, index) => (
                    <li key={index}>
                      <h4>
                        {item.title}
                      </h4>
                      <p>
                        {item.description}
                      </p>
                    </li>
                  ))
                }
              </ul>
            </Container>
          </div>

          <Footer />
        </>
      } 
    </>
  )
}

