import React, {useState} from "react";
import { Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateWebAppData } from '../api/request'

export default function UpdateBar() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const updateWebApp = async () => {
    setLoading(true);
    const res = await updateWebAppData();

    if(res.data.update === true) {
      window.location.href = "/";
    }
    else {
      setShow(!show);
    }
    setLoading(false);
  }
  return (
    <>
      <div style={{
        backgroundColor: "#004899",
        color: "#fff",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 5px 10px 5px"
      }}>
        <div>
          <span>
            Esta pagina tiene una actualización disponible. 
            Presione el boton para actualizar. 
            De lo contrario esta pagina de pedidos podria presentar problemas. 
          </span>
        </div>

        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {
            (loading) ? <>
              <Spinner 
                type="border"
                color="light" />
            </> :
            <>
              <button style={{
                border: "1px solid #fff",
                borderRadius: "0.25rem",
                backgroundColor: "transparent",
                color: "#fff"
              }}
              onClick={() => updateWebApp()}> Actualizar </button>
            </>
          }

        </div>
      </div>

      <Modal isOpen={show} toggle={() => setShow(!show)}>
        <ModalHeader toggle={() => setShow(!show)}>
          Mensaje
        </ModalHeader>
        <ModalBody>
          <p> Ha ocurrido un error al actualizar la aplicación web, por favor intente de nuevo. Si este error persiste comunicarse con soporte técnico.</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShow(!show)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
     </>
  )
}

