/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spinner, Button, Form, FormGroup, Label, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { setRecoverPassword } from './../../api/request';

export default function Reset() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const sendData = async () => {
    setLoading(true);

    if(String(email).trim() === "") {
      notifyAlert("warning", 'Mensaje', 'El email no puede estar vacio.');
    }
    else if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false ) {
      notifyAlert("warning", 'Mensaje', 'El email que ha introducido no es valido');
    }
    else {
      const res = await setRecoverPassword(email);
      console.log(res.status);

      if(res.status === 404) {
        notifyAlert("danger", 'Mensaje', res.data.message);
      }
      else if (res.status === 202) {
        notifyAlert("success", 'Mensaje', res.data.message);
      }
      else if (res.status === 200) {
        notifyAlert("success", 'Mensaje', res.data.message);
      }
      
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div>
        <img 
          style={{
            width: '100%'
          }}
          alt="..." 
          src={require('./../../assets/img/principal_banner.png').default} />
        
        <div 
          className="container" 
          style={{
            marginTop: '20px'
          }}>
          <Form>
            <FormGroup>
              <Label style={{fontWeight: '600'}}> E-mail </Label>
              <Input
                placeholder="Ingrese e-mail"
                type="email"
                value={email}
                onChange={(val) => setEmail(val.target.value) }
              />
            </FormGroup>
          </Form>

          <div 
            style={{
              textAlign: 'right', 
              marginBottom: '10px'
            }}>
            <Link to="/"> Regresar </Link>
          </div>

          <div style={{textAlign: 'center'}}>
            {
              (loading) ? 
                <Spinner style={{
                  color: "#004899"
                }} /> :
                <Button
                  style={{
                    backgroundColor: '#004899'
                  }}
                  className="btnLogin"
                  onClick={() => sendData()}>Recuperar</Button>
            }
          </div>

        </div>
      </div>
    </>
  )
}

