/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from "react";
import { Container, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from './../../components/Header';
import jwt_decode from 'jwt-decode';
import userPicture from './../../assets/img/user.png';
import NotificationAlert from "react-notification-alert";
import {AuthContext} from './../../context/AuthContext';
import {setPasswordData, getSuggestPriceData, setSuggestPriceData} from "./../../api/request";

export default function Config() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [user, setUser] = useState({});
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
	const [show, setShow] = useState(false);
	const [suggestPrice, setSuggestPrice] = useState(false);
  
  const {signOut} = useContext(AuthContext);

  useEffect( () => {
    funct();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            <b>
              {title}
            </b>
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const funct = async () => {
    await getUser();
    await getSuggestPrice();
  }

  const getUser = () => {
    const token = jwt_decode(window.localStorage.getItem('spm_token'));
    setUser(token);
    setLoading(false);
  }

  const getSuggestPrice = async() => {
    const res = await getSuggestPriceData();

    if(res.status === 200) {
      setSuggestPrice(res.data.suggest);
    }
  }

  const updatePassword = async() => {
    setLoadingBtn(true);

    if(String(password).trim() === "") {
      notifyAlert('warning', "Mensaje", "La contraseña esta vacia" )
    }
    else if(String(passwordRepeat).trim() === "") {
      notifyAlert('warning', "Mensaje", "La contraseña a repetir esta vacia" )
    }
    else {
      if(password === passwordRepeat) {
        const jsn = {
          "password": passwordRepeat
        }

        const res = await setPasswordData(jsn);

        if(res.status === 200) {
          notifyAlert('success', "Mensaje", "La contraseña ha sido actualizado");
          setPassword('');
          setPasswordRepeat('');
        }
      }
      else {
        notifyAlert('warning', "Mensaje", "Las contraseñas no coinciden");
      }
    }
    
    setLoadingBtn(false);
  }

  const closeSession = async() => {
    signOut();
  }

  const updateSuggestPrice = async() => {
    setLoadingPrice(true);

    const res = await setSuggestPriceData({"suggest_price": suggestPrice});

    if(res.status === 200) {
      notifyAlert('success', "Mensaje", "Su usuario ya puede trabajar con el precio sugerido");
    }
    else {
      notifyAlert('warning', "Mensaje", "Ha ocurrido un error al actualizar su usuario para el precio sugerido");
    }

    setLoadingPrice(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

  		<Header back bottom />
			<Container>
				<h2>Configuraciones</h2>
        <>
          {
            (loading) ? <> 
							<div style={{
								textAlign: 'center',
								marginBottom: '20px'
							}}>
								<Spinner style={{color: '#004899'}} />
          		</div>
						</>
        	: <>
              <div style={{textAlign: 'center', marginTop: '10px'}}>
                <img 
                  alt="..." 
                  style={{
                    width: '92px',
                    height: '92px',
                    border: '1px solid #444',
                    borderRadius: '50%',
                    objectFit: 'scale-down'
                  }}
                  src={(user.picture === null) ? userPicture : user.picture}/>
                
                  <h3 style={{marginTop: '5px'}}>{user.name}</h3>
                  <h5 style={{marginTop: '5px'}}>{user.email}</h5>
              </div>
              
              <hr />
              <h4>Actualización de contraseña</h4>
              <div>
                <div style={{marginTop: '15px'}}>
                  <label>Nueva contraseña</label>
                  <input 
                    value={password}
                    className="form-control"
                    onChange={(val) => setPassword(val.target.value)} />
                </div>

                <div style={{marginTop: '15px'}}>
                  <label>Repetir contraseña</label>
                  <input 
                    value={passwordRepeat}
                    className="form-control"
                    onChange={(val) => setPasswordRepeat(val.target.value)} />
                </div>
                
                <div style={{marginTop: '15px'}}>
                  {
                    (loadingBtn) ? <> 
                      <Spinner style={{color: '#004899'}} />
                    </> : <>
                      <Button
                        style={{
                          backgroundColor: '#004899'
                        }}
                        onClick={() => updatePassword()}>Actualizar</Button>
                    </>
                  }
                </div>

              </div>
              
              <hr />
              <h4>Precio sugerido</h4>
              <select
                className="form-control"
                style={{
                  appearance: 'auto',
                  width: '100%',
                  marginTop: '10px'
                }}
                value={suggestPrice}
                onChange={(val) => setSuggestPrice(val.target.value)}
              > 
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
              <p className="text-muted"> Esta opcion activara el manejo de precio sugerido donde las piezas tendra un costo mayor y podra aplicar descuento de ellos. </p>
              <p className="text-muted"> En caso de tener esta opcion desactivada los precios seran los originales y no se podra aplicar ningun descuento </p>

              <div style={{marginTop: '15px'}}>
                {
                  (loadingPrice) ? <> 
                    <Spinner style={{color: '#004899'}} />
                  </> : <>
                    <Button
                      style={{
                        backgroundColor: '#004899'
                      }}
                      onClick={() => updateSuggestPrice()}>Actualizar</Button>
                  </>
                }
              </div>
              <hr />
              <h4>Cuenta</h4>

              <div>
                <Button
                  style={{
                    backgroundColor: '#004899',
                    marginBottom: '25px'
                  }}
                  onClick={() => setShow(!show)}> Cerrar sesión </Button>
              </div>
          </>
          } 
        </>
      </Container>

      <Modal isOpen={show} toggle={() => setShow(!show)}>
				<ModalHeader toggle={() => setShow(!show)}>Mensaje</ModalHeader>
				<ModalBody>
					<div style={{textAlign: 'left'}}>
						¿Esta seguro que desea cerrar sesión?
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setShow(!show)}>
						Cancelar
					</Button>
					<Button
						color='danger'
						onClick={() => closeSession()}
					>
						Salir
					</Button>
				</ModalFooter>
			</Modal>
    </>
  )
}
