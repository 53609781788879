/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Header from './../components/Header';
import Footer from "./../components/Footer";
import { getCompanyData, sendMessageData } from "./../../../api/page";
import NotificationAlert from "react-notification-alert";

export default function Contact() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({title: '', email: '', phone: ''});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  

  useEffect(() => {
    const getMainData = async () => {
      const res = await getCompanyData();
      if(res.status === 200) {
        const { data } = res;
        console.log(data);
        
        setData({
          title: data.name,
          email: data.email,
          phone: data.phone,
        });
        setLoading(false);
      }
    }

    getMainData();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const sendMessage = async () => {

    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "El nombre es requerido");
    }
    else if(String(email).trim() === "") {
      notifyAlert("danger", "Error", "El correo electrónico es requerido");
    }
    else if(!String(email).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      notifyAlert("danger", "Error", "El correo electrónico no es válido");
    }
    else if(String(message).trim() === "") {
      notifyAlert("danger", "Error", "El mensaje es requerido");
    }
    else {
      const jsn = {
        name,
        email,
        message
      };

      console.log(jsn);

      const res = await sendMessageData(jsn);

      console.log(res);
      if(res.status === 200) {
        setName('');
        setEmail('');
        setMessage('');
        notifyAlert("success", "Éxito", "Mensaje enviado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ocurrió un error al enviar el mensaje");
      }
    }

  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header active="contact" />

      <div style={{
        width: `100%`,
        textAlign: `center`,
        marginTop: `50px`,
        marginBottom: `50px`,
      }}>
        <Container>
          <h1 style={{
            color: `#004899`,
            fontWeight: `700`
          }}>
            Contacto
          </h1>
        </Container>
      </div>
      
      <div style={{
        width: `100%`,
        marginTop: `50px`,
        marginBottom: `50px`,
      }}>
        <Container>
          <Row>
            <Col md="6">
              <div 
                style={{
                  marginBottom: `20px`
                }}
                className="form-group">
                <label 
                  className="form-control-label"
                  htmlFor="name"> Nombre </label>
                <input 
                  type="text" 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control" 
                  placeholder="Nombre" />
              </div>
              
              <div 
                style={{
                  marginBottom: `20px`
                }}
                className="form-group">
                <label htmlFor="email">Correo electrónico</label>
                <input 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email" 
                  className="form-control" 
                  placeholder="Correo electrónico" />
              </div>
              
              <div 
                style={{
                  marginBottom: `20px`
                }}
                className="form-group">
                <label htmlFor="message">Mensaje</label>
                <textarea 
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Mensaje"
                  className="form-control" 
                  rows="3"></textarea>
              </div>
              
              <div
              style={{
                textAlign: `right`
              }}>
                <button 
                  style={{
                    backgroundColor: `#004899`,
                    color: `#fff`,
                    border: `none`,
                    width: `150px`,
                  }}
                  onClick={sendMessage}
                  className="btn btn-primary">Enviar</button>
              </div>
            </Col>
            <Col md="6">
              <div style={{
                width: `100%`,
                textAlign: `center`,
                marginTop: `50px`,
                marginBottom: `50px`,
              }}>
                <Container>
                  {
                    (loading) ? <>
                      <Spinner style = {{ color: "#004899" }} />
                    </> : <>
                      <h1 style={{
                        color: `#004899`,
                        fontWeight: `700`
                      }}>
                        { data.title }
                      </h1>
                      <ul style={{
                        listStyle: 'none',
                        padding: '0px',
                        lineHeight: '2.5',
                      }}>
                        <li>
                          <i 
                            style={{
                              marginRight: "10px"
                            }}
                            className="fas fa-envelope" />
                          { data.email }
                        </li>
                        <li> 
                          <i 
                            style={{
                              marginRight: "10px"
                            }}
                            className="fas fa-phone" />
                          { data.phone }
                        </li>
                      </ul>
                    </>
                  }
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  )
}


