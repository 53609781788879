/* eslint-disable no-useless-escape */
import React from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";

const screeWidth = window.screen.width;
export default function Header({active}) {
  const [collapse, setCollapse] = React.useState(false);

  return (
    <>
      <div style={{
        backgroundColor: '#ffffff',
      }}>
        <Navbar color="faded" light>
          <NavbarBrand className="me-auto" href="/">
            <img alt="..." width={42} src={require('./../../../assets/img/logo.png').default} />
          </NavbarBrand>

          {
            (screeWidth > 427) ? <>
              <Nav>
                <NavItem>
                  <NavLink 
                    style={{
                      color: (active === "home") ? "#004899" : "#333"
                    }}
                    href="/">
                    Inicio
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink 
                  style={{
                      color: (active === "blog") ? "#004899" : "#333"
                    }}
                    href="/blog">
                    Blog
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink 
                    style={{
                      color: (active === "faq") ? "#004899" : "#333"
                    }}
                    href="/faq">
                    FAQ
                  </NavLink>
                </NavItem>

                <NavItem> 
                  <NavLink 
                    style={{
                      color: (active === "contact") ? "#004899" : "#333"
                    }}
                    href="/contact">
                    Contacto
                  </NavLink>
                </NavItem>

                <NavItem> 
                  <NavLink 
                    style={{
                      color: (active === "login") ? "#004899" : "#333"
                    }}
                    href="/login">
                    Ingresar
                  </NavLink>
                </NavItem>
              </Nav>
            </> : <>
              <NavbarToggler
                className="me-2"
                style={{
                  border: '0',
                  fontSize: '15px'
                }}
                onClick={() => setCollapse(!collapse)}
              />

              <Collapse navbar isOpen={collapse}>
                <Nav navbar>
                  <NavItem>
                    <NavLink 
                      style={{
                        color: (active === "home") ? "#004899" : "#333"
                      }}
                      href="/">
                      Inicio
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink 
                    style={{
                        color: (active === "blog") ? "#004899" : "#333"
                      }}
                      href="/blog">
                      Blog
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink 
                      style={{
                        color: (active === "faq") ? "#004899" : "#333"
                      }}
                      href="/faq">
                      FAQ
                    </NavLink>
                  </NavItem>

                  <NavItem> 
                    <NavLink 
                      style={{
                        color: (active === "contact") ? "#004899" : "#333"
                      }}
                      href="/contact">
                      Contacto
                    </NavLink>
                  </NavItem>

                  <NavItem> 
                    <NavLink 
                      style={{
                        color: (active === "login") ? "#004899" : "#333"
                      }}
                      href="/login">
                      Ingresar
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </>
          }

        </Navbar>
      </div>
    </>
  )
}

