/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Spinner, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Container, Row } from "reactstrap";
import Header from './components/Header';
import Footer from "./components/Footer";
import { getPageData, getBrandData } from "../../api/page";

export default function Page() {
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [mainData, setMainData] = useState({
    company: {},
    services: [],
    carousel: [],
  });
  const [brand, setBrandData] = useState([]);

  useEffect(() => {
    funct();
  }, []);
  
  const funct = async () => {
    await getMainData();
    await getBrand();
    setLoading(false);
  }
  
  const getMainData = async () => {
    const res = await getPageData();
    if(res.status === 200) {
      const { data } = res;
      setMainData({
        company: data.company,
        services: data.services,
        carousel: data.carousel,
      });
    }
  }
  
  const getBrand = async () => {
    const res = await getBrandData();
    if(res.status === 200) {
      const data = res.data.brands;
      setBrandData(data);
    }
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === mainData.carousel.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? mainData.carousel.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = mainData.carousel.map((item, key) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={key}
      >
        <img 
          style={{width: `100%`}} 
          src={item.picture} 
          alt={item.title} />
        <CarouselCaption
          captionText={item.title}
          captionHeader={item.description}
        />
      </CarouselItem>
    );
  });

  return (
    <>
      {
        (loading) ?
          <div style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`
          }}>
            <Spinner style={{ color: "#004899" }} />
          </div>
        :
        <>
          <Header active="home" />

          <div style={{
            width: `100%`,
            backgroundColor: `#fff`
          }}>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}>

              <CarouselIndicators
                items={mainData.carousel}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />

              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />

              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </div>

          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`,
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`
              }}>
                {mainData.company.name}
              </h1>
              <p>
                {mainData.company.description}
              </p>
            </Container>
          </div>

          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `20px`,
            marginBottom: `20px`
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`
              }}>
                Nuestras marcas
              </h1>

              <Row>
              {
                brand.map((item, index) => (
                  <Col key={index}>
                    <img
                      alt={item.name}
                      style={{
                        width: `164px`,
                        height: `164px`,
                        objectFit: `scale-down`,
                      }}
                      src={item.logo} />
                    <span> </span>
                  </Col>
                ))
              }
              </Row>
            </Container>
          </div>

          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`,
                marginBottom: `30px`
              }}>
                Nuestros servicios
              </h1>

              <Row>
                {
                  mainData.services.map((item, index) => (
                    <Col key={index} lg="4">
                      <img 
                        style={{
                          width: `70%`,
                          height: `70%`,
                          objectFit: `cover`
                        }}
                        alt={item.title}
                        src={item.picture} />
                      <h3> {item.title} </h3>
                      <p>
                        {item.description}
                      </p>
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </div>
          
          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`,
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`
              }}>
                LLegamos a todos los rincones del pais
              </h1>
              <img 
                alt="venezuela"
                style={{
                  width: `30%`,
                }}
                src={require(`../../assets/img/ven.png`).default}
                />
            </Container>
          </div>


          <Footer />
        </>
      }
    </>
  )
}

