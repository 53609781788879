/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Spinner, Col, Container, Row, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Header from './../components/Header';
import Footer from "./../components/Footer";
import { Link } from "react-router-dom";
import { getBlogData } from "./../../../api/page";

export default function Blog() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getPosts = async () => {
      const res = await getBlogData();
      console.log(res);

      if(res.status === 200) {
        const { data } = res;
        setData(data);
        setLoading(false);
      }
    }

    getPosts();
  }, []);
  
  return (
    <>
      {
        (loading) ?
          <div style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`
          }}>
            <Spinner style={{ color: "#004899" }} />
          </div>
        :
        <>
          <Header active="blog" />

          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`,
          }}>
            <Container>
              <h1 style={{
                color: `#004899`,
                fontWeight: `700`
              }}>
                Ultimos post
              </h1>
            </Container>
          </div>

          <div style={{
            width: `100%`,
            textAlign: `center`,
            marginTop: `50px`,
            marginBottom: `50px`
          }}>
            <Container>
    
              <Row>
                {
                  data.map((item, index) => {
                    return (
                      <Col lg="3" 
                        key={index}
                        style={{
                          marginTop: `20px`,
                        }}>
                          <Link
                            style={{
                              textDecoration: `none`,
                              color: "#333" 
                            }}
                            to={`/blog/${item.id}`}>
                            <Card>
                              <img
                                alt={item.title}
                                src={item.picture}
                              />
                              <CardBody>
                                <CardTitle tag="h5">
                                  {item.title}
                                </CardTitle>
                                <CardText>
                                  {item.text}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Link>
                      </Col>
                    )
                  })
                }

              </Row>
            </Container>
          </div>

          <Footer />
        </>
      }
    </>
  )
}

