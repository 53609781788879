/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner, Container, Table, Card, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from './../../components/Header';
import { getSuggestPriceData, getBillHistoryDetailsData, setOrderStatus, getCompanyData } from './../../api/request';
import NotificationAlert from "react-notification-alert";
import dayjs from 'dayjs';

const liStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px'
}

export default function History() {
  const window_screen = window.screen.width;
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [bill, setBill] = useState({});
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [note, setNote] = useState('');
  const [suggestPrice, setSuggestPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [fixedDiscount, setFixedDiscount] = useState(0);

  useEffect( () => {
    funct();
  }, []);

  const funct = async () => {
    await getBillHistoryDetails();
    await getSuggestPrice();
    await getCompany();
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            <b>
              {title}
            </b>
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getSuggestPrice = async() => {
    const res = await getSuggestPriceData();

    if(res.status === 200) {
      setSuggestPrice(res.data.suggest);
      setFixedDiscount(res.data.fixed_discount)
      //setDiscount(res.data.fixed_discount)
    }
  }

 const getCompany = async () => {
    const res = await getCompanyData();

    if(res.status === 200) {
      setMaxDiscount(res.data.discount);
    }
    else {
      notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud", 5);
    }
  }

  const getBillHistoryDetails = async () => {
    const res = await getBillHistoryDetailsData(id);
    
    if(res.status === 200) {
      console.log(res.data.data);
      setBill(res.data.bill_data);
      setDetails(res.data.details);
      setNote(res.data.bill_data.comments);

      if(totalDiscount === 0) {
        setTotalDiscount(res.data.bill_data.total);
      }

      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  const discountValidation = () => {
    if(discount < 0 || discount > maxDiscount) {
      notifyAlert("warning", "Mensaje", "El descuento debe ser mayor a 0 y menor a 50", 5);
    }
    else {
      const total = bill.total - (discount / 100) * bill.total;
      setTotalDiscount(total);
      getBillHistoryDetails();
    }
  }

  const cleanDiscountValidation = () => {
    const total = bill.total - (0 / 100) * bill.total;
    setTotalDiscount(total);
    setDiscount(0);
    getBillHistoryDetails();
  }

  const verifyCode = (code) => {
    return (code === null) ? "Por asignar" : code;
  }

  const verifyInfo = (c) => {
    return (c === null) ? "Sin información" : c;
  }

  const calcTotal = (p,q) => {
    const total = p * q;
    return Number(total).toFixed(2);
  }

  const updateOrder = async (status) => {
    setLoadingBtn(true);
    const jsn = {
      "id": id,
      "status": status,
      "note": note,
      "discount": discount,
      "total": totalDiscount
    }

    const res = await setOrderStatus(jsn);
    
    if(res.status === 200) {
      notifyAlert('success', 'Mensaje', res.data.message);
      getBillHistoryDetails();
      setShowModal(false);
      setShowModal2(false);
    }
    else {
      notifyAlert('danger', 'Mensaje', res.data.message);
      setShowModal(false);
      setShowModal2(false);
    }
    setLoadingBtn(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

  		<Header back bottom />
			<Container>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px'
        }}>
          <div>
            <h2>Factura</h2>
          </div>

          {
            (bill.status === "Sin procesar") ? <>
              {
                (loadingBtn) ?
                <>
                  <div className="text-center">
                    <Spinner style={{color: '#004899'}} />
                  </div>
                </> : <>
                  <div>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => setShowModal(!showModal)}
                    > Cancelar </Button>
                    <Button
                      size="sm"
                      style={{
                        height: '32px',
                        color: '#ffffff',
                        backgroundColor: '#004899',
                        marginLeft: '10px',
                        border: '0px'
                      }}
                      onClick={() => setShowModal2(!showModal2)}
                    > Procesar </Button>
                  </div>
                </>
              }
            </> : <></>
          }
          </div>

        {
          (loading) ?
          <>
            <div className="text-center">
              <Spinner style={{color: '#004899'}} />
            </div>
          </> 
          :
          <>
            <ul style={{
              listStyle: 'none',
              padding: '0px'
            }}>
              <li style={liStyle}>
                <b>Factura: </b>
                <span>{verifyCode(bill.code)}</span>
              </li>
              <li style={liStyle}>
                <b>Estado: </b>
                <span>{bill.status}</span>
              </li>
              <li style={liStyle}>
                <b>Total: </b>
                <span>${Number(bill.total).toFixed(2)}</span>
              </li>
              <li style={liStyle}>
                <b>Fecha: </b>
                <span>{dayjs(bill.date).format('DD-MM-YYYY HH:mm')}</span>
              </li>
              <li>
                <b>Comentario: </b>
                <br />
                <textarea
                  style={{
                    width: '100%',
                    height: '100px',
                  }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                >
                </textarea>
              </li>
            </ul>

            <hr />
            <h4>Cliente</h4>
            <ul style={{
              listStyle: 'none',
              padding: '0px'
            }}>
              <li style={liStyle}>
                <b>Nombre: </b>
                <span style={{textAlign: 'right'}}>{bill.cli_name}</span>
              </li>
              <li style={liStyle}>
                <b>Contacto: </b>
                <span style={{textAlign: 'right'}}>{verifyInfo(bill.cli_contact)}</span>
              </li>
              <li style={liStyle}>
                <b>Cedula/RIF: </b>
                <span style={{textAlign: 'right'}}>{bill.cli_rif}</span>
              </li>
              <li style={liStyle}>
                <b>Teléfono: </b>
                <span style={{textAlign: 'right'}}>{bill.cli_phone}</span>
              </li>
              <li style={liStyle}>
                <b>E-mail: </b>
                <span style={{textAlign: 'right'}}>{verifyInfo(bill.cli_email)}</span>
              </li>
              <li style={liStyle}>
                <b>Estado: </b>
                <span style={{textAlign: 'right'}}>{verifyInfo(bill.cli_state)}</span>
              </li>
              <li style={liStyle}>
                <b>Ciudad: </b>
                <span style={{textAlign: 'right'}}>{verifyInfo(bill.cli_city)}</span>
              </li>
              <li style={liStyle}>
                <b>Dirección: </b>
                <span style={{textAlign: 'right'}}>{verifyInfo(bill.cli_address)} </span>
              </li>
            </ul>

            <hr />
            <h4>Detalle</h4>
            {
              (window_screen < 760) ? <>
                <ul style={{
                  listStyle: 'none',
                  padding: '0px'
                }}>
                  {
                    details.map( (d, i) => (
                      <li 
                        key={i} 
                        style={{
                          marginBottom: '10px'
                        }}
                      >
                        <Card style={{
                          padding: '10px'
                        }}>
                          <div style={{display: 'flex'}}>
                            <div>
                              <img 
                                style={{
                                  width: '100px',
                                  marginRight: '5px',
                                  height: 'fit-content',
                                  marginTop: '13px'
                                }}
                                alt="..." 
                                src={d.picture} />
                            </div>

                            <div style={{
                              display: 'grid'
                            }}>
                              <span><b>Nombre: </b>{d.name}</span>
                              <span><b>Marca: </b>{d.brand}</span>
                              <span><b>Precio: </b>${d.price}</span>
                              <span><b>Cantidad: </b>{d.quantity}</span>
                              <span><b>Total: </b>${calcTotal(d.price, d.quantity)}</span>
                            </div>
                          </div>
                        </Card>
                      </li>
                    ))
                  }
                </ul>
              </> : 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Imagen </th>
                      <th> Nombre </th>
                      <th> Marca </th>
                      <th> Precio </th>
                      <th> Cantidad </th>
                      <th> Total </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      details.map( (d, i) => (
                        <tr key={i}>
                          <th>{++i}</th>
                          <th>
                            <img 
                              style={{
                                width: '100px'
                              }}
                              alt="..." 
                              src={d.picture} />
                          </th>
                          <td>{d.name}</td>
                          <td>{d.brand}</td>
                          <td>${d.price}</td>
                          <td>{d.quantity}</td>
                          <td>${calcTotal(d.price, d.quantity)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </>
            }

            {
              suggestPrice === 0 ? <></> : <>
                <div style={{
                  paddingTop: '10px',
                  paddingBottom: '1px',
                  borderTop: '1px solid #898989',
                  borderBottom: '1px solid #898989',
                  marginTop: '10px',
                  marginBottom: '10px',
                  backgroundColor: '#ffffff'
                }}>
                  {
                    (bill.status === "Pendiente") ? <></> : 
                    <> 
                      <ul
                        id="infoPayment"
                        style={{
                          borderBottom: '1px solid #898989',
                        }}
                      >
                        <li>
                          <h3> Descuento ({fixedDiscount}%) </h3>
                        </li>
                        <li>
                          <input
                            type="number"
                            value={discount}
                            min={fixedDiscount}
                            max={maxDiscount}
                            onChange={val => setDiscount(val.target.value)}
                            style={{
                              width: '100px',
                              height: '30px',
                              borderRadius: '4px',
                              padding: '5px'
                            }}
                            placeholder="Descuento"
                          />
                          <button
                            style={{backgroundColor: '#004899'}} 
                            className="btn btn-primary sm"
                            onClick={() => discountValidation()}
                          >
                            Aplicar
                          </button>
                          <button
                            color="danger"
                            className="btn btn-primary sm"
                            onClick={() => cleanDiscountValidation()}
                          >
                            Limpiar
                          </button>
                        </li>
                      </ul>
                    </>
                  }

                  <ul id="infoPayment">
                    <li>
                      <h2>Total</h2>
                    </li>
                    <li>
                      <h3>${Number(bill.total).toFixed(2)}</h3>
                    </li>
                  </ul>
                  <ul id="infoPayment">
                    <li>
                      <h2>Total Con Descuento</h2>
                    </li>
                    <li>
                      <h3>${Number(totalDiscount).toFixed(2)}</h3>
                    </li>
                  </ul>
                </div>
              </>
            }
          </>
        }
      </Container>

      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)}>Mensaje</ModalHeader>
        <ModalBody>
          ¿Esta seguro en <b>cancelar</b> la orden?
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowModal(!showModal)}>
            Cerrar
          </Button>
          <Button 
            color="danger"
            onClick={() => updateOrder(3)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showModal2} toggle={() => setShowModal2(!showModal2)}>
        <ModalHeader toggle={() => setShowModal2(!showModal2)}>Mensaje</ModalHeader>
        <ModalBody>
          ¿Esta seguro en <b>aprobar</b> la orden?
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowModal2(!showModal2)}>
            Cerrar
          </Button>
          <Button 
            style={{
              color: '#ffffff',
              backgroundColor: '#004899',
              border: '0px'
            }}
            onClick={() => updateOrder(1)}>
            Procesar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

