/* eslint-disable no-unused-vars */
var BASE_URL;
const URL = window.location.href;

const local = String(URL).includes('localhost');

if(local) {
  BASE_URL = "http://localhost:7071/api";
}
else {
	BASE_URL = `https://spm-back-fn.azurewebsites.net/api`;
}

export const update     = `${BASE_URL}/update`;
export const auth       = `${BASE_URL}/auth`;
export const brands     =	`${BASE_URL}/brands`;
export const brand      =	`${BASE_URL}/brand`;
export const pieces     =	`${BASE_URL}/pieces`;
export const piece      =	`${BASE_URL}/piece`;
export const cart       =	`${BASE_URL}/cart`;
export const shopping   = `${BASE_URL}/shopping`;
export const client     = `${BASE_URL}/client`;
export const historical = `${BASE_URL}/historical`;
export const link       = `${BASE_URL}/link`;
export const stats      = `${BASE_URL}/stats`;
export const order      = `${BASE_URL}/order`;
export const page       = `${BASE_URL}/page`;
export const blog       = `${BASE_URL}/blog`;
export const catalog    = `${BASE_URL}/catalog`;
export const company    = `${BASE_URL}/company`;
export const suggest   = `${BASE_URL}/suggest-price`;
