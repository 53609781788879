/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Container, Table, Card } from "reactstrap";
import Header from './../../components/Header';
import { getBillHistoryData } from './../../api/request';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";

export default function History() {
  const window_screen = window.screen.width;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect( () => {
    getBillHistory();
  }, []);

  const getBillHistory = async () => {
    const res = await getBillHistoryData();
    
    if(res.status === 200) {
      setData(res.data.data);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  const verifyCode = (code) => {
    return (code === null) ? "Por asignar" : code;
  }

  const verifyClient = (c) => {
    return (c === null) ? "Información no registrada" : c;
  }

  return (
    <>
  		<Header back bottom />
			<Container>
				<h2>Historial</h2>

        {
          (loading) ?
          <>
            <div className="text-center">
              <Spinner style={{color: '#004899'}} />
            </div>
          </> 
          :
          <>
            {
              (window_screen < 760) ? <>
                <ul style={{
                  listStyle: 'none',
                  padding: '0px'
                }}>
                  {
                    data.map( (d, i) => (
                      <li key={i} style={{
                        marginBottom: '10px'
                      }}>
                        <Card style={{
                          padding: '10px'
                        }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                            <span>{verifyCode(d.code)}</span>
                            <span>{d.status}</span>
                          </div>
                          <div style={{
                            display: 'grid'
                          }}>
                            <span><b>Nombre: </b>{d.client_name}</span>
                            <span><b>Total: </b>${d.payment}</span>
                            <span><i className="far fa-calendar"/>{' '}{dayjs(d.date).format('DD-MM-YYYY')}</span>
                          </div>
                          <div style={{marginTop: '8px'}}>
                            <Link
                              to={`/historical/${d.id}`}
                              style={{
                                width: '100%',
                                backgroundColor: '#004899',
                                border: '1px solid #004899'
                              }}
                              className="btn btn-primary btn-sm">Ver detalle</Link>
                          </div>
                        </Card>
                      </li>
                    ))
                  }
                </ul>
              </> : 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Codigo </th>
                      <th> Estado </th>
                      <th> Cliente </th>
                      <th> Precio </th>
                      <th> Fecha </th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map( (d, i) => (
                        <tr key={i}>
                          <th>{++i}</th>
                          <td>{verifyCode(d.code)}</td>
                          <td>{d.status}</td>
                          <td>{verifyClient(d.client_name)}</td>
                          <td>${d.payment}</td>
                          <td>{dayjs(d.date).format('DD-MM-YYYY')}</td>
                          <td>
                            <Link to={`/historical/${d.id}`}>Ver detalle</Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </>
            }
          </>
        }
      </Container>
    </>
  )
}

