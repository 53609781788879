/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Container, Row, Col} from "reactstrap";
import { getCompanyData } from "../../../api/page";

export default function Footer() {
  const [data, setData] = useState({
    title: '',
    email: '',
    logo: '',
    phone: '',
    time: '',
    instagram: '',
  });

  useEffect(() => {
    const getMainData = async () => {
      const res = await getCompanyData();
      if(res.status === 200) {
        const { data } = res;
        console.log(data);
        
        setData({
          title: data.title,
          email: data.email,
          logo: data.secondary_logo,
          phone: data.phone,
          time: data.time,
          instagram: data.instagram,
        });
      }
    }

    getMainData();
  }, []);

  return (
    <>
      <div style={{
        backgroundColor: '#004899',
        color: '#ffffff',
        padding: '20px 0px',
      }}>
        <Container>
          <Row>
            <Col>
              <img 
                alt="..."
                src={data.logo}
              />
            </Col>

            <Col>
              <h4> Atención al Cliente </h4>
              <ul style={{
                listStyle: 'none',
                padding: '0px',
              }}>
                <li>
                  {data.title}
                </li>
                <li>
                  { data.time }
                </li>
              </ul>
            </Col>

            <Col>
              <h4> Contáctanos </h4>
              <ul style={{
                listStyle: 'none',
                padding: '0px',
              }}>
                <li>
                  <a href={`mailto:${data.email}`}
                    style={{
                      color: '#ffffff',
                      textDecoration: 'none'
                    }}
                  >
                    <i 
                      style={{
                        marginRight: "10px"
                      }}
                      className="fas fa-envelope" />
                    { data.email }
                  </a>
                </li>
                <li> 
                  <a href={`tel:${data.phone}`}/>
                  <i 
                    style={{
                      marginRight: "10px"
                    }}
                    className="fas fa-phone" />
                  { data.phone }
                </li>
              </ul>
            </Col>

            
            <Col>
              <h4> Síguenos </h4>
              <ul style={{
                listStyle: 'none',
                padding: '0px',
              }}>
                <li>
                  <a 
                    href={data.instagram}
                    target="_blank"
                    style={{
                      color: '#ffffff',
                      textDecoration: 'none'
                    }} rel="noreferrer"
                  >
                    <i 
                      style={{
                        fontSize: '30px'
                      }}
                      className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  )
}

