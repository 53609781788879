/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Table, Spinner, Container, Card, Button } from 'reactstrap';
import Header from './../../components/Header';
import { getStatsData } from './../../api/request';
import NotificationAlert from "react-notification-alert";
import { Bar, Doughnut } from "react-chartjs-2";

export default function Stats() {
	const notificationAlertRef = React.useRef(null);
  const arrMonth = [
    {
      "name": "Enero",
      "value": 1
    },
    {
      "name": "Febrero",
      "value": 2
    },
    {
      "name": "Marzo",
      "value": 3
    },
    {
      "name": "Abril",
      "value": 4
    },
    {
      "name": "Mayo",
      "value": 5
    },
    {
      "name": "Junio",
      "value": 6
    },
    {
      "name": "Julio",
      "value": 7
    },
    {
      "name": "Agosto",
      "value": 8
    },
    {
      "name": "Septiembre",
      "value": 9
    },
    {
      "name": "Octubre",
      "value": 10
    },
    {
      "name": "Noviembre",
      "value": 11
    },
    {
      "name": "Diciembre",
      "value": 12
    }
  ];
	const [loading, setLoading] = useState({
    page:false, 
    profit: false,
    brand: false,
    client: false,
    piece: false
  });
	const [error, setError] = useState(false);
	const [data, setData] = useState({
    total: '',
    web: '',
    transit: ''
  });
  const [date, setDate] = useState([]);
  const [selBillProfit, setSelBillProfit] = useState('W');
  const [selYearProfit, setSelYearProfit] = useState('');
  const [profitTotal, setProfitTotal] = useState('');
  const [arrProfit, setArrProfit] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Ganancias',
    backgroundColor: '#004899',
    data: []
  }]});
  const [selBillBrand, setSelBillBrand] = useState('W');
  const [selMonthBrand,setSelMonthBrand ] = useState('');
  const [selYearBrand, setSelYearBrand] = useState('');
  const [brandTotal, setBrandTotal] = useState({
    total: '',
    brand: ''
  });
  const [arrBrand, setArrBrand] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Marcas',
    backgroundColor: '#004899',
    data: [],
    options: {
      indexAxis: 'y',
    }
  }]});
  const [arrBrandData, setArrBrandData] = useState([]);
  const [selBillClient, setSelBillClient] = useState('W');
  const [selMonthClient, setSelMonthClient] = useState('');
  const [selYearClient, setSelYearClient] = useState('');
  const [clientData, setClientData] = useState([]);

  const [selBillPiece, setSelBillPiece] = useState('W');
  const [selMonthPiece,setSelMonthPiece ] = useState('');
  const [selYearPiece, setSelYearPiece] = useState('');
  const [arrPieceData, setArrPieceData] = useState([]);
  const [pieceTotal, setPieceTotal] = useState({
    total: '',
    piece: ''
  });
  const [arrPiece, setArrPiece] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Piezas',
    backgroundColor: '#004899',
    data: [],
    options: {
      indexAxis: 'y',
    }
  }]});

  useEffect(() => {
    functExc();
  }, []);

  const NumberFormat = (num) => {
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(num);
  }

  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  const functExc  = async () => {
    await getDates()
    await getStats();
    await getProfit();
    await getBrand();
    await getClient();
    await getPiece();

    setSelYearProfit(String(new Date().getFullYear()));
    setSelMonthBrand(String(new Date().getMonth() + 1));
    setSelYearBrand(String(new Date().getFullYear()));
    setSelMonthClient(String(new Date().getMonth() + 1));
    setSelYearClient(String(new Date().getFullYear()));
    setSelMonthPiece(String(new Date().getMonth() + 1));
    setSelYearPiece(String(new Date().getFullYear()));
  }

  const getDates = async () => {
    var arrDate = [];
    var initDate = 2020;
    const finalDate = new Date().getFullYear();

    for (let i = initDate; i <= finalDate; i++) {
      arrDate.push(i);
    }
    setDate(arrDate);
  }

  const getStats = async () => {
    setLoading(true);
    
    const jsn = { "graph": "GENERAL" }
    
    const res = await getStatsData(jsn);
    console.log(res);

    if(res.status === 200) {
      setData({
        total: res.data.total,
        web: res.data.web,
        transit: res.data.transit,
      });
    }
    else {
      setError(true);
    }
    setLoading(false);
  }

  const getProfit = async () => {
    setLoading({...loading, profit: true});

    const jsn = { 
      "graph": "PROFIT",
      "year": (selYearProfit === "") ? String(new Date().getFullYear()) : selYearProfit,
      "bill_type": (selBillProfit === "") ? "W" : selBillProfit
    }

    const res = await getStatsData(jsn);
    const profit = res.data;

    const valuesText = profit.map( (v) => {
      return arrMonth[v.month-1].name;
    });
    const valuesData = profit.map( (v) => {
      return v.total;
    });

    setArrProfit({
      legend: profit,
      labels: valuesText, 
      datasets: [{
      label: 'Ganancias',
      backgroundColor: '#004899',
      data: valuesData,
      type: 'line'
    }]});
    
    //sumar valores de un arreglo
    const total = valuesData.reduce((a, b) => a + b, 0);
    setProfitTotal(total);

    setLoading({...loading, profit: false});
  }

  const getBrand = async () => {
    setLoading({...loading, brand: true});

    const jsn = { 
      "graph": "BRAND",
      "month": (selMonthBrand === "") ? String(new Date().getMonth() + 1) : selMonthBrand,
      "year": (selYearBrand === "") ? String(new Date().getFullYear()) : selYearBrand,
      "bill_type": (selBillBrand === "") ? "W" : selBillBrand
    }

    const res = await getStatsData(jsn);
    const brand = res.data;

    const valuesText = brand.map( (v) => {
      return `${v.BrandName} (${v.Total})`;
    });

    const valuesData = brand.map( (v) => {
      return v.Total;
    });

    setArrBrand({
      legend: brand,
      labels: valuesText,
      indexAxis: 'y',
      datasets: [{
        axis: 'y',
        label: 'Marcas',
        backgroundColor: "#004899",
        data: valuesData,
        type: 'bar',
        indexAxis: 'y',
      }],
    });

    const total = valuesData.reduce((a, b) => a + b, 0);
    setBrandTotal({
      total: total,
      brand: brand.length
    });
    setArrBrandData(brand);

    setLoading({...loading, brand: false});
  }

  const getClient = async () => {
    setLoading({...loading, client: true});

    const jsn = { 
      "graph": "CLIENT",
      "month": (selMonthClient === "") ? String(new Date().getMonth() + 1) : selMonthClient,
      "year": (selYearClient === "") ? String(new Date().getFullYear()) : selYearClient,
      "bill_type": (selBillClient === "") ? "W" : selBillClient
    }
    const res = await getStatsData(jsn);
    const clients = res.data;

    setClientData(clients);
    setLoading({...loading, client: false});
  }

  const getPiece = async () => {
    setLoading({...loading, piece: true});
    
    const jsn = { 
      "graph": "PIECES",
      "month": (selMonthPiece === "") ? String(new Date().getMonth() + 1) : selMonthPiece,
      "year": (selYearPiece === "") ? String(new Date().getFullYear()) : selYearPiece,
      "bill_type": (selBillPiece === "") ? "W" : selBillPiece
    }
    const res = await getStatsData(jsn);
    const piece = res.data;

    const valuesText = piece.map( (v) => {
      return v.name;
    });
    const valuesData = piece.map( (v) => {
      return v.total;
    });
    
    var colors = [];
    
    for(var i of piece) {
      colors.push(dynamicColors());
    }

    var total = 0;
    var pieces = 0;

    for(var v of piece) {
      total += v.amount;
      pieces += v.total;
    }

    setArrPiece({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Piezas',
        backgroundColor: colors,
        data: valuesData,
        type: 'doughnut'
      }]
    });

    setPieceTotal({
      total: pieces,
      piece: total
    });
    setArrPieceData(piece);
    setLoading({...loading, piece: false});
  }

	return (
		<>
			<div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

			<Header back bottom />
			<Container>
				<h2>Estadística</h2>
				<div>
					{
						(loading.page) ? <> 
							<div style={{
								textAlign: 'center',
								marginBottom: '20px'
							}}>
								<Spinner style={{color: '#004899'}} />
          		</div>
						</>
        	: 
					(error) ? <> 
						<div style={{
							textAlign: "center",
							marginBottom: "20px",
							fontSize: "27px",
							color: "#444",
						}}>
							<label> Se ha presentado un error, volver a intentarlo </label>
						</div>
					</>
					:
					<>
            <Row>
              <Col md="4">
                <Card style={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                  <h4> Ventas totales </h4>
                  <label style={{
                    fontSize: '20px',
                    fontWeight: '600'
                  }}> {data.total} </label>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                  <h4> Ventas de pedidos web </h4>
                  <label style={{
                    fontSize: '20px',
                    fontWeight: '600'
                  }}> {data.web} </label>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                  <h4> Ventas de transito </h4>
                  <label style={{
                    fontSize: '20px',
                    fontWeight: '600'
                  }}> {data.transit} </label>
                </Card>
              </Col>

            </Row>

            <hr />
            
            <div style={{marginBottom: "20px"}}>
              <Row>
                <Col md="6">
                  <h4>Ventas por meses</h4>
                </Col>

                <Col md="6">
                  <ul style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '0px',
                    listStyle: 'none'
                  }}>
                    <li style={{ marginRight: "10px"}}>
                      <select 
                        value={selBillProfit}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelBillProfit(e.target.value)}>
                        <option value="">Tipo de factura</option>
                        <option value="W">Pedido Web</option>
                        <option value="T">Transito</option>
                      </select>
                    </li>
                    <li>
                      <select 
                        value={selYearProfit}
                        className="form-control"
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelYearProfit(e.target.value)}>
                        <option value="">Seleccionar Año</option>
                        {
                          date.map( (a, i) => (
                            <option key={i} value={a}> {a} </option>
                          ))
                        }
                      </select>
                    </li>
                    <li>
                      {
                        (loading.profit) ? <>
                          <div style={{marginLeft: "10px"}}>
                            <Spinner style={{color: '#004899'}} />
                          </div>
                        </>:
                        <Button style={{
                          backgroundColor: '#004899',
                          border: 'none',
                          marginLeft: '10px'
                        }} 
                        onClick={() => getProfit()}>
                          Buscar
                        </Button>
                      }
                    </li>
                  </ul>
                </Col>
              </Row>
              
              <div style={{height: "350px"}}>
                <Bar
                  data={arrProfit}
                  options= {{maintainAspectRatio: false}}
                />
              </div>

              <h3>Total: <b>{NumberFormat(profitTotal)}</b></h3>
            </div>

            <hr /><br />

            <div style={{marginBottom: "20px"}}>
              <Row>
                <Col md="6">
                  <h4>Marcas por fecha</h4>
                </Col>

                <Col md="6">
                  <ul style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '0px',
                    listStyle: 'none'
                  }}>
                    <li style={{ marginRight: "10px"}}>
                      <select 
                        value={selBillBrand}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelBillBrand(e.target.value)}>
                        <option value="">Tipo de factura</option>
                        <option value="W">Pedido Web</option>
                        <option value="T">Transito</option>
                      </select>
                    </li>
                    <li style={{ marginRight: "10px"}}>
                      <select 
                        value={selMonthBrand}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelMonthBrand(e.target.value)}>
                        <option value="">Seleccionar mes</option>
                        {
                          arrMonth.map( (a, i) => (
                            <option key={i} value={a.value}> {a.name} </option>
                          ))
                        }
                      </select>
                    </li>
                    <li>
                      <select 
                        value={selYearBrand}
                        className="form-control"
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelYearBrand(e.target.value)}>
                        <option value="">Seleccionar Año</option>
                        {
                          date.map( (a, i) => (
                            <option key={i} value={a}> {a} </option>
                          ))
                        }
                      </select>
                    </li>
                    <li>
                      {
                        (loading.brand) ? <>
                          <div style={{marginLeft: "10px"}}>
                            <Spinner style={{color: '#004899'}} />
                          </div>
                        </>:
                        <Button style={{
                          backgroundColor: '#004899',
                          border: 'none',
                          marginLeft: '10px'
                        }} 
                        onClick={() => getBrand()}>
                          Buscar
                        </Button>
                      }
                    </li>
                  </ul>
                </Col>
              </Row>
              
              <Row>
                <Col lg="9">
                  <Bar
                    data={arrBrand}
                    options= {{maintainAspectRatio: false}}
                  />
                </Col>

                <Col lg="3">
                  <ul>
                    {
                      arrBrandData.map( (a, i) => (
                        <li key={i}>
                          <b> {a.BrandName}: </b>
                          <label> {a.Total} </label>
                        </li>
                      ))
                    }
                  </ul>
                  <h3>Total de marcas: <b>{brandTotal.brand}</b></h3>
                  <h3>Total de piezas: <b>{brandTotal.total}</b></h3>
                </Col>
              </Row>
            </div>

            <hr /><br />

            <div style={{marginBottom: "20px"}}>
              <Row>
                <Col md="12">
                  <ul style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px',
                    listStyle: 'none'
                  }}>
                    <li>
                      <h4>Top 5 de clientes por fecha</h4>
                    </li>
                    <li style={{ marginRight: "10px", display: "flex"}}>
                      <select 
                        value={selBillClient}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelBillClient(e.target.value)}>
                        <option value="">Tipo de factura</option>
                        <option value="W">Pedido Web</option>
                        <option value="T">Transito</option>
                      </select>
                      <select 
                        value={selMonthClient}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelMonthClient(e.target.value)}>
                        <option value="">Seleccionar mes</option>
                        {
                          arrMonth.map( (a, i) => (
                            <option key={i} value={a.value}> {a.name} </option>
                          ))
                        }
                      </select>
                      <select 
                        value={selYearClient}
                        className="form-control"
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelYearClient(e.target.value)}>
                        <option value="">Seleccionar Año</option>
                        {
                          date.map( (a, i) => (
                            <option key={i} value={a}> {a} </option>
                          ))
                        }
                      </select>
                      {
                        (loading.brand) ? <>
                          <div style={{marginLeft: "10px"}}>
                            <Spinner style={{color: '#004899'}} />
                          </div>
                        </>:
                        <Button style={{
                          backgroundColor: '#004899',
                          border: 'none',
                          marginLeft: '10px'
                        }} 
                        onClick={() => getClient()}>
                          Buscar
                        </Button>
                      }
                    </li>
                  </ul>
                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Contacto</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          clientData.map( (a, i) => (
                            <tr key={i}> 
                              <td>{a.name} </td>
                              <td>{a.contact} </td>
                              <td>{a.total} </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>

              </Row>
            </div>

            <hr /><br />

            <div style={{marginBottom: "20px"}}>
              <Row>
                <Col md="6">
                  <h4>Piezas por fecha</h4>
                </Col>

                <Col md="6">
                  <ul style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '0px',
                    listStyle: 'none'
                  }}>
                    <li style={{ marginRight: "10px"}}>
                      <select 
                        value={selBillPiece}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelBillPiece(e.target.value)}>
                        <option value="">Tipo de factura</option>
                        <option value="W">Pedido Web</option>
                        <option value="T">Transito</option>
                      </select>
                    </li>
                    <li style={{ marginRight: "10px"}}>
                      <select 
                        value={selMonthPiece}
                        className="form-control" 
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelMonthPiece(e.target.value)}>
                        <option value="">Seleccionar mes</option>
                        {
                          arrMonth.map( (a, i) => (
                            <option key={i} value={a.value}> {a.name} </option>
                          ))
                        }
                      </select>
                    </li>
                    <li>
                      <select 
                        value={selYearPiece}
                        className="form-control"
                        style={{appearance: "listbox"}}
                        onChange={(e) => setSelYearPiece(e.target.value)}>
                        <option value="">Seleccionar Año</option>
                        {
                          date.map( (a, i) => (
                            <option key={i} value={a}> {a} </option>
                          ))
                        }
                      </select>
                    </li>
                    <li>
                      {
                        (loading.brand) ? <>
                          <div style={{marginLeft: "10px"}}>
                            <Spinner style={{color: '#004899'}} />
                          </div>
                        </>:
                        <Button style={{
                          backgroundColor: '#004899',
                          border: 'none',
                          marginLeft: '10px'
                        }} 
                        onClick={() => getPiece()}>
                          Buscar
                        </Button>
                      }
                    </li>
                  </ul>
                </Col>
              </Row>
              
              <div style={{height: "600px"}}>
                <Doughnut 
                  data={arrPiece}
                  options= {{maintainAspectRatio: false}}/> 
              </div>

              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      arrPieceData.map( (a, i) => (
                        <tr key={i}> 
                          <td>{a.name} </td>
                          <td>{a.total} </td>
                          <td>${a.amount} </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>

              <h3>Total de piezas: <b>{pieceTotal.total}</b></h3>
              <h3>Total en venta: <b>${pieceTotal.piece}</b></h3>
            </div>
					</>
					}
				</div>
			</Container>
		</>
	)
}
