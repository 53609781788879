/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { Table, Button, Spinner, Container, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getSuggestPriceData, getCartData, deletePieceData, getClientsData, setShoppingData, modifyQuantityPieceData, getCompanyData } from './../../../api/request';

export default function Cart() {
  const window_screen = window.screen.width;
	const notificationAlertRef = React.useRef(null);
	const [loading, setLoading] = useState({cart: true, total: true});
	const [error, setError] = useState({show: false, text: ''});
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [arrClient, setArrClient] = useState([]);
	const [client, setClient] = useState({
		name: '', 
		contact: '', 
		email: '',
		cardId: '',
		phone: '',
		state: '',
		city: '',
		address:''
	});
	const [total, setTotal] = useState('');
	const [id, setId] = useState();
	const [show, setShow] = useState(false);
	const [comments, setComments] = useState('');
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loadingClient, setloadingClient] = useState(false);
	const [showModal, setShowModal] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [fixedDiscount, setFixedDiscount] = useState(0);
  const [suggestPrice, setSuggestPrice] = useState(0);

	useEffect( () => {
    funct();
	}, []);

  const funct = async () => {
    await getSuggestPrice();
    await getCompany();
    await getCart();
  }

  const notifyAlert = (type, title, message, time) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text" style={{display: 'inline'}}>
          <span className="alert-title" data-notify="title">
            {" "}
            <b>
              {title}
            </b>
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "far fa-bell",
      autoDismiss: time,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

	const getCart = async () => {
		setLoading({cart: true, total: true});
		const res = await getCartData();
		var total = 0;

		for(var p of res.data) {
      if(discount > 0) {
        p.price = p.price - (p.price * (discount / 100));
        total += (p.price *  p.quantity)
      }
      else {
        total += (p.price *  p.quantity)
      }
		}

		if(res.status === 200) {
			setData(res.data);
			setTotal(total);
			setError({show:false, text: ''});
		}
		else {
			setError({show:true, text: res.data});
		}
		setLoading({cart: false, total: false});
	}

  const getCompany = async () => {
    const res = await getCompanyData();

    if(res.status === 200) {
      setMaxDiscount(res.data.discount);
    }
    else {
      notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud", 5);
    }
  }

  const getSuggestPrice = async() => {
    const res = await getSuggestPriceData();

    if(res.status === 200) {
      setSuggestPrice(res.data.suggest);
      setDiscount(res.data.fixed_discount);
      setFixedDiscount(res.data.fixed_discount);
      setMaxDiscount(res.data.max_discount);
    }
  }

  const calcTotal = (p , q) => {
    const _total = p * q;

    return Number(_total).toFixed(2);
  }

	const showToDelete = async (id) => {
		setId(id);
		setShow(!show);
	}
  
  const loadingUpdate = (piece, state) => {
    document.getElementById(`dv${piece.id}`).style.display = (state === true) ? 'block' : 'none';
    document.getElementById(`ul${piece.id}`).style.display = (state === true) ? 'none' : 'flex';
  }

  const addPieces = async (piece) => {
    loadingUpdate(piece, true);
    const quantity = Number(document.getElementById(`inp${piece.id}`).value) + 1;

    const res = await modifyQuantityPieceData(piece, quantity, 'add');

    if(res.data === 'update') {
      getCart();
    }
    else {
      notifyAlert("danger", "Mensaje", res.data, 5);
    }
    loadingUpdate(piece, false);
  }

  const delPieces = async (piece) => {
    loadingUpdate(piece, true);

    const quantity = Number(document.getElementById(`inp${piece.id}`).value) - 1;

    if(Number(quantity) > 0) {
      const res = await modifyQuantityPieceData(piece, quantity, 'sub');
  
      if(res.data === 'update') {
        getCart();
      }
      else {
        notifyAlert("danger", "Mensaje", res.data, 5);
      }
    }
    else {
      notifyAlert("warning", "Mensaje", 'Si no quiere esta pieza debe eliminarla', 7);
    }

    loadingUpdate(piece, false);
  }

	const deletePiece = async () => {
		const piece = data[id];

		const jsn = {
			"id": piece.id,
			"name": piece.name,
			"warehouse": piece.warehouse
		}

		const res = await deletePieceData(jsn);

		if(res.status === 200) {
			setShow(!show);
			getCart();
		}
		else {
			setShow(!show);
			notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud", 5);
		}
	}

  const getClients = async ()  => {
    setloadingClient(true);

    if(String(search).trim() === "") {
      notifyAlert("danger", "Mensaje", "Debe ingresar el nombre del cliente", 5);
    }
    else {
      const res = await getClientsData(search);

      if(res.status === 200) {
        setArrClient(res.data.data);
      }
      else {
        setShowModal(!showModal);
        notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud", 5);
      }
    }

    setloadingClient(false);
  }

  const setClientData = (c) => {
    setClient(c);
    setShowModal(false);
  }

  const discountValidation = () => {
    if(discount < 0 || discount > maxDiscount) {
      notifyAlert("warning", "Mensaje", "El descuento debe ser mayor a 0 y menor a 50", 5);
    }
    else {
      getCart();
    }
  }

	const shopping = async () => {
    setLoadingBtn(true);

		if(data.length === 0) {
			notifyAlert("warning", "Mensaje", "El carrito de compra esta vacio", 5);
		}
		else if(client.name === '') {
				notifyAlert("warning", "Mensaje", "Debe seleccionar a su cliente", 5);
		}
    else if(suggestPrice === 1 && comments === "") {
      notifyAlert("warning", "Mensaje", "Debe ingresar un comentario, indicar el descuento o alguna nota para la facturacion", 5);
    }
		else {
			const jsn = {
				"client": client,
				"comment": comments,
        "cart_type": "normal",
        "discount": discount,
			}
      console.log(jsn)
      
			const res = await setShoppingData(jsn);
      console.log(res);

			if(res.status === 200) {
				getCart();
				setTotal('0');
				setClient({
					name: '', 
					contact: '', 
					email: '',
					cardId: '',
					phone: '',
					state: '',
					city: '',
					address:''
				});
        setDiscount(0);
				setComments('');
				notifyAlert("success", "Mensaje", res.data.message, 5);
			}
      else if(res.status === 500) {
				notifyAlert("danger", "Mensaje", `Se ha presentado un error en la conexión de internet o con el servidor. Resive su historial para ver su pedido en caso de haber sido procesado. De no estarlo puede volverlo a intentar, si esto persiste debe comunicarse con soporte tecnico`, 15);
      }
			else {
				notifyAlert("danger", "Mensaje", res.data.message, 10);
			}
		}

    setLoadingBtn(false);
	}

	return (
		<>
			<div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

			<Container>
				<div style={{marginTop: '5px'}}>
					{
						(loading.cart) ? <> 
							<div style={{
								textAlign: 'center',
								marginBottom: '20px'
							}}>
								<Spinner style={{color: '#004899'}} />
          		</div>
						</>
        	: 
					(error.show) ? <> 
						<div style={{
							textAlign: "center",
							marginBottom: "20px",
							fontSize: "27px",
							color: "#444",
						}}>
							<label> {error.text} </label>
						</div>
					</>
					:
					<>
						{
							(data.length === 0) ? <>
							<div style={{
									textAlign: "center",
									marginBottom: "20px",
									fontSize: "27px",
									color: "#444",
								}}>
									<label> No hay piezas en el carrito </label>
								</div>
							</> : <>
							{
								data.map((d, i) => (
									<Card key={i} style={{padding: '5px', marginBottom: '10px'}}>
										<ul className="cardCart">
											<li>
												<img className="cartPicture" alt="..." src={d.picture} />
											</li>
											<li style={{width: '100%'}}>
												<ul className="cartDetails">
													<li>
														<span className="titlePiece" style={{
                              fontSize: '23px'
                            }}>{d.name}</span>
													</li>
													<li 
                            style={{
                              width: '100%', 
                              marginTop: '5px', 
                              marginRight: '10px', 
                              display: 'flex', 
                              justifyContent: 'space-between'}}>
														<div>
															<span style={{
																fontSize: '20px',
																fontWeight: '500',
																color: '#444'
															}}>
                                Precio: ${Number(d.price).toFixed(2)}
                              </span>
                              <br />
                              <span style={{
																fontSize: '20px',
																fontWeight: '500',
																color: '#444'
															}}>Monto total: ${calcTotal(d.price, d.quantity)}</span>
														</div>

														<div style={{marginRight: '10px'}}>
                              <span style={{
                                display:'block', 
                                marginTop:'0',
                                fontSize:'20px', 
                                fontWeight: '500', 
                                textAlign: 'center',
                                color: '#444'}}>Cantidad</span>
                                  
                                  <div 
                                    id={`dv${d.id}`}
                                    style={{textAlign: 'center', display: 'none'}}>
                                    <Spinner style={{color: '#004899'}} />
                                  </div> 

                                  <ul 
                                    id={`ul${d.id}`}
                                    style={{
                                      display: "inline-flex",
                                      padding: 0,
                                      listStyle: 'none'
                                    }}>
                                    <li style={{ 
                                      display: 'block'
                                    }}>
                                      <i style={{
                                        fontSize: '25px', 
                                        marginTop: '6px', 
                                        cursor: 'pointer'
                                      }} 
                                      className="fas fa-minus-circle"  
                                      onClick={() => delPieces(d)}/>
                                    </li>
                                    <li style={{marginLeft: '10px', marginRight: '10px'}}>
                                      <input
                                        placeholder="1"
                                        type="number"
                                        min="0"
                                        id={`inp${d.id}`}
                                        value={d.quantity}
                                        style={{
                                          width: '70px'
                                        }}
                                        className="form-control inputNumber" 
                                        readOnly
                                      />
                                    </li>
                                    <li style={{
                                      display: 'block',
                                      lineHeight: 2.5
                                    }}>
                                      <i style={{
                                        fontSize: '25px', 
                                        marginTop: '6px', 
                                        cursor: 'pointer'
                                      }} 
                                      className="fas fa-plus-circle" 
                                      onClick={() => addPieces(d)}/>
                                    </li>
                                  </ul>
														</div>
													</li>
												</ul>
											</li>
										</ul>
                    <ul style={{
                        display: 'flex',
                        padding: '0px',
                        listStyle: 'none',
                        justifyContent: 'flex-end'
										}}>
											<li>
                        <button
                          style={{marginLeft:'10px'}}
                          className="btn btn-danger sm"
                          onClick={() => showToDelete(i)}> Eliminar </button>
											</li>
										</ul>
									</Card>
								))
							}
							</>
						}
					</>
					}
				</div>
        
        {
          (suggestPrice === 1) ? <>
            <div>
              <ul id="infoPayment">
                <li>
                  <h2>Descuento</h2>
                </li>
                <li>
                  <input
                    type="number"
                    value={discount}
                    min={fixedDiscount}
                    max={maxDiscount}
                    onChange={val => setDiscount(val.target.value)}
                    style={{
                      width: '100px',
                      height: '30px',
                      borderRadius: '4px',
                      padding: '5px'
                    }}
                    placeholder="Descuento"
                  />
                  <button
                    style={{backgroundColor: '#004899'}} 
                    className="btn btn-primary sm"
                    onClick={() => discountValidation()}
                  >
                    Aplicar
                  </button>
                </li>
              </ul>
            </div>
          </> : <></>
        }

				<div style={{
					paddingTop: '10px',
					paddingBottom: '1px',
					borderTop: '1px solid #898989',
					borderBottom: '1px solid #898989',
					marginBottom: '10px',
					backgroundColor: '#ffffff'
				}}>
					<ul id="infoPayment">
						<li>
							<h2>Total</h2>
						</li>
						<li>
							{
								(loading.total) ? <>
									<div style={{marginRight: '5px'}}>
										<Spinner style={{color: '#004899'}} />
									</div>
								</> : 
								(error.show) ? <> 
									<h3>$0</h3>
								</>:
								<>
									<h3>${Number(total).toFixed(2)}</h3>
								</>
							}
						</li>
					</ul>
				</div>

				<div>
					<ul id="infoClient">
						<li>
							<h2>Cliente</h2>
						</li>
            <li>
              <button 
                style={{backgroundColor: '#004899'}} 
                className="btn btn-primary sm"
                onClick={() => setShowModal(!showModal)}> Buscar </button>
						</li>
					</ul>

					<div>
            <ul id="clientData">
              <li>
                <b>Nombre:</b>
                <span>{client.name}</span>
              </li>
              <li>
                <b>RIF:</b>
                <span>{client.rif}</span>
              </li>
              <li>
                <b>Telefono:</b>
                <span>{client.phone}</span>
              </li>
              <li>
                <b>E-mail:</b>
                <span>{client.email}</span>
              </li>
              <li>
                <b>Dirección:</b>
                <br />
                <span style={{textAlign:'right'}}>{client.address}</span>
              </li>
              <li style={{display: 'block'}}>
                <b style={{display: 'block'}}>Comentario {(suggestPrice === 0) ? "(opcional)" : ""} </b>
                <textarea style={{
                  width: '100%',
                  height: '70px',
                  resize: 'none',
                  borderRadius: '4px',
                  padding: '5px'
                }} 
                value={comments}
                onChange={(val) => setComments(val.target.value)}
                placeholder="Ingrese su comentario"/>
              </li>
            </ul>
					</div>
				</div>

				<div style={{textAlign: 'center', marginBottom: '30px'}}>
          {
            (loadingBtn) ? <>
              <Spinner style={{color: '#004899'}} />
            </>: <>
              <button 
                className='btFinish'
                onClick={() => shopping()}> Finalizar compra </button>
            </>
          }
				</div>
			</Container>

			<Modal isOpen={show} toggle={() => setShow(!show)}>
				<ModalHeader toggle={() => setShow(!show)}>Mensaje</ModalHeader>
				<ModalBody>
					<div style={{textAlign: 'center'}}>
						¿Esta seguro en eliminar la pieza del carrito?
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setShow(!show)}>
						Cancelar
					</Button>
					<Button
						color='danger'
						onClick={() => deletePiece()}
					>
						Eliminar
					</Button>
				</ModalFooter>
			</Modal>

      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
				<ModalHeader toggle={() => setShowModal(!showModal)}>Cliente</ModalHeader>
				<ModalBody>
		      <div style={{display:'flex'}}>
            <input 
              style={{
                marginRight: 3
              }}
              value={search}
              className="form-control" 
              onChange={val => setSearch(val.target.value)}/>
            <Button
              onClick={() => getClients()}>Buscar</Button>
          </div>
          <div>
            {
              (loadingClient) ? <>
              <div style={{textAlign: 'center', marginTop:'10px'}}>
                <Spinner style={{color: '#004899'}} />
              </div>
              </> : <>
                  {
                    (window_screen < 560) ? <>
                      <ul style={{
                        listStyle: 'none',
                        padding: '0px',
                        marginTop: '10px',
                        maxHeight: '350px',
                        overflow: 'scroll'
                      }}>
                        {
                          arrClient.map( (c, i) => (
                            <li 
                              key={i} 
                              style={{
                                marginBottom: '10px'
                              }}
                            >
                              <Card style={{
                                padding: '10px'
                              }}>
                                <div>
                                  <div style={{
                                    display: 'grid'
                                  }}>
                                    <span>{(c.name === '') ? 'Data no registrada' : c.name}</span>
                                    <span>{(c.rif === '') ? 'Data no registrada' : c.rif}</span>
                                    <span>{(c.state === '') ? 'Data no registrada' : c.state}</span>
                                    <span>{(c.city === '') ? 'Data no registrada' : c.city}</span>
                                    <Button 
                                      size="sm"
                                      style={{
                                        backgroundColor: '#004899',
                                        border: '1px solid #004899'
                                      }}
                                      onClick={() => setClientData(c)}>Seleccionar</Button>
                                  </div>
                                </div>
                              </Card>
                            </li>
                          ))
                        }
                      </ul>
                    </> : 
                    <>
                      {
                        <div style={{
                          maxHeight: '350px',
                          overflow: 'scroll'
                        }}>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>RIF</th>
                                <th>Ciudad</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {
                                arrClient.map( (c, i) => (
                                  <tr key={i}>
                                    <td>
                                      {(c.name === '') ? 'No data' : c.name}
                                    </td>
                                    <td>
                                      {(c.rif === '') ? 'No data' : c.rif}
                                    </td>
                                    <td>
                                      {(c.city === '') ? 'No data' : c.city}
                                    </td>
                                    <td>
                                      <Button 
                                        size="sm"
                                        variant="secondary"
                                        onClick={() => setClientData(c)}>Seleccionar</Button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </div>
                      }
                    </>
                  }
                </>
              }
          </div>
        </ModalBody>
				<ModalFooter>
					<Button onClick={() => setShowModal(!showModal)}>
						Cerrar
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}
