import axios from "axios";
import * as CONS from "./constants";
import { getToken } from './../utils/token';

export async function getCompanyData() {
  try {
		const res = await axios.get(`${CONS.page}/company`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getPageData() {
  try {
		const res = await axios.get(`${CONS.page}/page`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBrandData() {
  try {
		const res = await axios.get(CONS.brands, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getFaqData() {
  try {
		const res = await axios.get(`${CONS.page}/faq`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBlogData() {
  try {
    const res = await axios.get(CONS.blog, {
      headers: {
        "auth": getToken("spm_token")
      }
    });
    return res;
  }
  catch(err) {
    return err.response;
  }
}

export async function getBlogByIdData(id) {
  try {
    const res = await axios.get(`${CONS.blog}?id=${id}`, {
      headers: {
        "auth": getToken("spm_token")
      }
    });
    return res;
  }
  catch(err) {
    return err.response;
  }
}

export async function sendMessageData(jsn) {
  try {
    const res = await axios.post(`${CONS.page}/email`, jsn, {
      headers: {
        "auth": getToken("spm_token")
      }
    });
    return res;
  }
  catch(err) {
    return err.response;
  }
}