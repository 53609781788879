import axios from "axios";
import * as CONS from "./constants";
import { getToken } from './../utils/token';

export async function checkUpdateData() {
  try {
		const res = await axios.get(CONS.update, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function updateWebAppData() {
  try {
		const res = await axios.post(CONS.update, true, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setLoginData(jsn) {
	try {
		const res = await axios.post(CONS.auth, jsn);
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setRecoverPassword(email) {
	try {
		const res = await axios.get(`${CONS.auth}?email=${email}`);
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getAllBrandData() {
	try {
		const res = await axios.get(CONS.brands, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBrandData(bn) {
	try {
		const res = await axios.get(`${CONS.brand}/${bn}`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getPiecesByBrandAndCategoryData(bn, cat) {
	try {
		const res = await axios.get(`${CONS.pieces}/${bn}/${cat}`,
      {
        headers: {
          "auth": getToken("spm_token")
        }
      }
    );
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setPieceToCartData(jsn) {
	try {
		const res = await axios.post(CONS.piece, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getPiecesByNameData(name) {
	try {
		const res = await axios.get(`${CONS.piece}/?piece=${name}`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getCartData() {
	try {
		const res = await axios.get(CONS.cart, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function deletePieceData(jsn) {
	try {
		const res = await axios.post(CONS.cart, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getClientsData(txt) {
	try {
		const res = await axios.get(`${CONS.client}/${txt}`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setShoppingData(jsn) {
	try {
		const res = await axios.post(CONS.shopping, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBillHistoryData() {
	try {
		const res = await axios.get(CONS.historical, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBillHistoryDetailsData(id) {
	try {
		const res = await axios.get(`${CONS.historical}?id=${id}`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getAllLinkData() {
	try {
		const res = await axios.get(CONS.link, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setClientAccessData(jsn) {
	try {
		const res = await axios.post(CONS.link, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getStatsData(jsn) {
  try {
		const res = await axios.post(CONS.stats, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setPasswordData(jsn) {
	try {
		const res = await axios.put(CONS.auth, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setOrderStatus(jsn) {
	try {
		const res = await axios.put(CONS.order, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

/** TRANSIT **/
export async function getAllTransitBrandData() {
	try {
		const res = await axios.get(`${CONS.brands}?transit=1`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitBrandData(bn) {
	try {
		const res = await axios.get(`${CONS.brand}/${bn}?transit=1`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitPiecesByBrandAndCategoryData(bn, cat) {
	try {
		const res = await axios.get(`${CONS.pieces}/${bn}/${cat}?transit=1`,
      {
        headers: {
          "auth": getToken("spm_token")
        }
      }
    );
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitPiecesByNameData(name) {
	try {
		const res = await axios.get(`${CONS.piece}/?piece=${name}&transit=1`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitCartData() {
	try {
		const res = await axios.get(`${CONS.cart}?transit=1`, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

/**
 * 
 * @param {*} piece 
 * @param {*} quantity 
 * @param {*} opc 
 * @returns 
 */
export async function modifyQuantityPieceData(piece, quantity, opc) {
	try {
		const res = await axios.put(CONS.cart, {
      'piece': piece,
      'quantity': quantity,
      'option': opc 
    }, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function downloadCatalogData(jsn) {
  try {
		const res = await axios.post(CONS.catalog, jsn, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res.data;
	}
	catch(err) {
		return err.response;
	}
}

export async function getCompanyData() {
	try {
		const res = await axios.get(CONS.company, {
			headers: {
				"auth": getToken("spm_token")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getSuggestPriceData() {
  try {
    const res = await axios.get(CONS.suggest, {
      headers: {
        "auth": getToken("spm_token")
      }
    });
    return res;
  }
  catch(err) {
    return err.response;
  }
}

export async function setSuggestPriceData(jsn) {
  try {
    const res = await axios.post(CONS.suggest, jsn, {
      headers: {
        "auth": getToken("spm_token")
      }
    });
    return res;
  }
  catch(err) {
    return err.response;
  }
}